// ** Router Import
import React from "react";
import Router from "./router/Router";
import { createTheme, useTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material/styles";
import { useSelector } from "react-redux";

const App = props => {
  const skin = useSelector(state => state.theme.darkMode);
  //console.log("skin", skin);

  const theme = createTheme({
    palette: {
      mode: skin === 'dark' ? 'dark' : 'light',
      primary: {
        main: "#4580F3",
      },
      secondary: {
        main: "#FF8C00",
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
