import { combineReducers } from "redux";

import sportsReducer from "./sports";
import authReducer from "./auth";
import casinoReducer from "./casino";
import tenantsReducer from "./tenants";
import sitesReducer from "./sites";
import statScoreReducer from "./statScore";
import classesReducer from "./classes";
import selectsReducer from "./selects";
import selectsMarketingEventsReducer from "./selects-marketing-events";
import gamesReducer from "./games";
import lotteriesReducer from "./lotteries";
import liveCasinoReducer from "./live-casino";
import dataSourcesCacheReducer from "./data-sources-cache";
import pagesLinksCacheReducer from "./pages-links-cache";
import playerHistoryReducer from "./player-history";
import appReducer from "./app";
import themeReducer from "./theme";
import tagsTreeReducer from "./tags-tree";
import bulkBonusGrantReducer from "./bulk-bonus-grant";
import bulkPrizeGrantReducer from "./bulk-prize-grant";
import bulkTagsGrantReducer from "./bulk-tags-grant";
import bulkDeductionGrantReducer from "./bulk-deduction-grant";
import bulkUpdateAffiliateInfoReducer from "./bulk-update-affiliate-info";
import bulkWithdrawals from "./bulk-withdrawals";
import { rt_notifications } from "./rt_notifications";
import jackpotReducer from "./jackpot";
import dataReducer from "./data";
import contentManagementReducer from "./content-management";
import loyaltyReducer from "./loyalty";
import druidReducer from "./druid";

import layoutReducer from "./layout";
import navbarReducer from "../../vuexy/redux/reducers/navbar";
import tableFiltersSortingReducer from "./table-filters-sorting";
import templatizationEditPage from "./templatization-edit-page";
import sideMenuReducer from "./side-menu";

import editorReducer from "./editor";

import tableSearchFilterReducer from "./table-search-filter";
import notificationTemplatesReducer from "./notification-templates";

import selectorsReducer from "./selectors";

import countryFlagsReducer from "./country-flags";
import adminSettingsReducer from "./admin-settings";

const rootReducer = combineReducers({
  sports: sportsReducer,
  auth: authReducer,
  casino: casinoReducer,
  tenants: tenantsReducer,
  sites: sitesReducer,
  statScore: statScoreReducer,
  classes: classesReducer,
  selects: selectsReducer,
  games: gamesReducer,
  lotteries: lotteriesReducer,
  liveCasino: liveCasinoReducer,
  dataSourcesCache: dataSourcesCacheReducer,
  pagesLinksCache: pagesLinksCacheReducer,
  playerHistory: playerHistoryReducer,
  app: appReducer,
  theme: themeReducer,
  tagsTree: tagsTreeReducer,
  bulkBonusGrant: bulkBonusGrantReducer,
  bulkPrizeGrant: bulkPrizeGrantReducer,
  bulkTagsGrant: bulkTagsGrantReducer,
  bulkDeductionGrant: bulkDeductionGrantReducer,
  bulkUpdateAffiliateInfo: bulkUpdateAffiliateInfoReducer,
  bulkWithdrawals: bulkWithdrawals,
  notifications: rt_notifications,
  layout: layoutReducer,
  navbar: navbarReducer,
  tableFiltersSorting: tableFiltersSortingReducer,
  templatizationEditPage: templatizationEditPage,
  sideMenu: sideMenuReducer,
  data: dataReducer,
  contentManagement: contentManagementReducer,
  editor: editorReducer,
  jackpot: jackpotReducer,
  loyalty: loyaltyReducer,
  tableSearchFilter: tableSearchFilterReducer,
  selectsMarketingEvents: selectsMarketingEventsReducer,
  druid: druidReducer,
  notificationTemplates: notificationTemplatesReducer,
  selectors: selectorsReducer,
  countryFlags: countryFlagsReducer,
  adminSettings: adminSettingsReducer,
});

export default rootReducer;
