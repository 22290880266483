import { bulkPrizeGrant } from "../constants";

export const bulkPrizeGrantStart = data => ({
  type: bulkPrizeGrant.START,
  data
});

export const bulkPrizeGrantDone = (data) => ({
  type: bulkPrizeGrant.DONE,
  data
});

export const bulkPrizeGrantClear = () => ({
  type: bulkPrizeGrant.CLEAR,
});

export const bulkPrizeGrantProgress = () => ({
  type: bulkPrizeGrant.PROGRESS
});
