export const sportsConstants = {
  FETCH_SPORTS: "FETCH_SPORTS",
  LOADED_SPORTS: "LOADED_SPORTS",
  SELECTED_SPORTS_CHANGED: "SELECTED_SPORTS_CHANGED",
  POOL_SPORTS_CHANGED: "POOL_SPORTS_CHANGED",
  SAVE_SPORTS: "SAVE_SPORTS",
};
// new authConstants
export const userConstants = {
  AUTH_CHECK_REQUEST: "AUTH_CHECK_REQUEST",
  AUTH_CHECK_SUCCESS: "AUTH_CHECK_SUCCESS",
  AUTH_CHECK_FAILURE: "AUTH_CHECK_FAILURE",

  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  AUTH_IN_PROGRESS: "USER_AUTH_IN_PROGRESS",

  LOGOUT: "USER_LOGOUT",
};
// old admin authConstants
// export const authConstants = {
// CHECK: "AUTH_CHECK",                     -> AUTH_CHECK_REQUEST
// START: "AUTH_START",                     -> LOGIN_REQUEST:
// SUCCESS: "AUTH_SUCCESS",                 -> AUTH_CHECK_SUCCESS
// ERROR: "AUTH_ERROR",                     -> AUTH_CHECK_FAILURE
// LOGOUT: "AUTH_LOGOUT",                   -> LOGIN_FAILURE
// LOGOUT_SUCCESS: "AUTH_LOGOUT_SUCCESS",   -> LOGOUT
// IN_PROGRESS: "AUTH_IN_PROGRESS"          -> LOGIN_SUCCESS
// };

export const casinoConstants = {
  AUTH: "CASINO_AUTH",
  INIT: "CASINO_INIT",
};

export const tenantsConstants = {
  FETCH_ALL: "TENANTS_FETCH_ALL",
  LOADED_ALL: "TENANTS_LOADED_ALL",
  FETCH: "TENANTS_FETCH",
  LOADED: "TENANTS_LOADED",
  IS_LOADING: "TENANTS_IS_LOADING",
  ADD_TENANT: "TENANTS_ADD_TENANT",
  COPY_TENANT_CONFIG: "TENANTS_COPY_TENANT_CONFIG",
  COPY_TENANT_CONFIG_DONE: "TENANTS_COPY_TENANT_CONFIG_DONE",
  DELETE_TENANT_REMOTE: "TENANTS_DELETE_TENANT_REMOTE",
  DELETE_TENANT_LOCAL: "TENANTS_DELETE_TENANT_LOCAL",
  SET_CURRENT_TENANT: "TENANTS_SET_CURRENT_TENANT",
};

export const sitesConstants = {
  FETCH_ALL: "SITES_FETCH_ALL",
  FETCH: "SITES_FETCH",
  SET_CURRENT_SITE: "SITES_SET_CURRENT_SITE",

  LOADED: "SITES_LOADED",
  LOADED_ALL: "SITES_LOADED_ALL",
};

export const statScoreConstants = {
  SAVE: "STAT_SCORE_SAVE",
  SAVE_MULTI: "STAT_SCORE_SAVE_MULTI",
};

export const classesConstants = {
  LOAD: "CLASSES_LOAD",
  LOADING: "CLASSES_LOADING",
  LOADED: "CLASSES_LOADED",
  CLEAR: "CLASSES_CLEAR",
  CLEAR_LOADING: "CLASSES_CLEAR_LOADING",
};

export const tagsConstants = {
  LOAD: "TAGS_LOAD",
  LOADING: "TAGS_LOADING",
  LOADED: "TAGS_LOADED",
  CLEAR: "TAGS_CLEAR",
};

export const selectsConstants = {
  LOAD: "SELECTS_LOAD",
  LOADED: "SELECTS_LOADED",
};

export const selectsMarketingEventsConstants = {
  LOAD: "SELECTS_MARKETING_EVENTS_LOAD",
  LOADED: "SELECTS_MARKETING_EVENTS_LOADED",
};

export const gamesConstants = {
  LOAD: "GAMES_LOAD",
  LOADED: "GAMES_LOADED",
};

export const lotteriesConstants = {
  LOAD: "LOTTERIES_LOAD",
  LOADED: "LOTTERIES_LOADED",
};

export const liveCasinoConstants = {
  LOAD: "TABLES_LOAD",
  LOADED: "TABLES_LOADED",
};

export const dataSourcesConstants = {
  LOAD: "DATA_SOURCES_LOAD",
  LOADED: "DATA_SOURCES_LOADED",
  LOAD_CONTENT: "DATA_SOURCES_LOAD_CONTENT",
  LOADED_CONTENT: "DATA_SOURCES_LOADED_CONTENT",
  CLEAR: "DATA_SOURCES_CLEAR",
  LOAD_CONTENTS: "DATA_SOURCES_LOAD_CONTENTS",
  LOADED_CONTENTS: "DATA_SOURCES_LOADED_CONTENTS",
  UPDATE_CONTENT: "UPDATE_CONTENT",
};

export const pagesLinksConstants = {
  LOAD: "PAGES_LINKS_LOAD",
  LOADED: "PAGES_LINKS_LOADED",
  CLEAR: "PAGES_LINKS_CLEAR",
};

export const appConstants = {
  SHOW_VERSION_CHECK_MODAL: "APP_SHOW_VERSION_CHECK_MODAL",
  HIDE_VERSION_CHECK_MODAL: "APP_HIDE_VERSION_CHECK_MODAL",
  BUILD_INFO_RECEIVED: "APP_BUILD_INFO_RECEIVED",
};

export const playerHistoryConstants = {
  LOAD: "PLAYER_HISTORY_LOAD",
  LOADED: "PLAYER_HISTORY_LOADED",
  TOGGLE_LOAD: "PLAYER_HISTORY_TOGGLE_LOAD",
  TOGGLE_LOAD_GAME_SESSION_ROUNDS: "PLAYER_HISTORY_TOGGLE_LOAD_GAME_SESSION_ROUNDS",
  CHANGED_START_DATE: "PLAYER_HISTORY_CHANGED_START_DATE",
  UPDATE_START_DATE: "PLAYER_HISTORY_UPDATE_START_DATE",
  CHANGED_END_DATE: "PLAYER_HISTORY_CHANGED_END_DATE",
  UPDATE_END_DATE: "PLAYER_HISTORY_UPDATE_END_DATE",
  LOAD_GAME_SESSION_ROUNDS: "PLAYER_HISTORY_UPDATE_GAME_SESSION_ROUNDS_LOAD",
  LOADED_GAME_SESSION_ROUNDS: "PLAYER_HISTORY_UPDATE_GAME_SESSION_ROUNDS_LOADED",
  SELECT_TRANSACTION_TYPES: "PLAYER_HISTORY_SELECT_TRANSACTION_TYPES",
  LOAD_PROVIDER_WITHDRAW_STATUSES: "PLAYER_HISTORY_LOAD_WITHDRAW_STATUSES",
  LOADED_PROVIDER_WITHDRAW_STATUSES: "PLAYER_HISTORY_LOADED_WITHDRAW_STATUSES",
  LOAD_OKTO_TRANSACTION_STATUSES: "PLAYER_HISTORY_LOAD_OKTO_TRANSACTION_STATUSES",
  LOADED_OKTO_TRANSACTION_STATUSES: "PLAYER_HISTORY_LOADED_OKTO_TRANSACTION_STATUSES",
  LOAD_SKRILL_TRANSACTION_STATUSES: "PLAYER_HISTORY_LOAD_SKRILL_TRANSACTION_STATUSES",
  LOADED_SKRILL_TRANSACTION_STATUSES: "PLAYER_HISTORY_LOADED_SKRILL_TRANSACTION_STATUSES",
  LOAD_AIRCASH_TRANSACTION_STATUSES: "PLAYER_HISTORY_LOAD_AIRCASH_TRANSACTION_STATUSES",
  LOADED_AIRCASH_TRANSACTION_STATUSES: "PLAYER_HISTORY_LOADED_AIRCASH_TRANSACTION_STATUSES",
  DATE_PICKER_FORMAT: "YYYY-MM-DD",
  DEFAULT_DAYS_DIFF: 30,
  MAX_DAYS_DIFF: 90,
  WITHDRAWAL_PROCESS_TYPE: 4,
  cashProviders: {
    101: "Cashier",
    103: "Viva",
    102: "Safecharge",
    104: "Balance Adjustment",
    105: "Okto",
    106: "Aircash",
    107: "[Legacy] Skrill - Deposit", // Outdated
    108: "[Legacy] Skrill - Withdrawal", // Outdated
    109: "Viva Bank Transfer",  //withdraw
    110: "BridgerPay",
    111: "Skrill", // Updated after merging deposit and withdrawals on the same ID
    112: "Bridgerpay Apple Pay",
    113: "BetLion",
  },
  withdrawalTypes: {
    1: "Deposit",
    2: "Pending",
    3: "Cancel",
    4: "Process",
    5: "Declined",
    10: "Deposit Adjustment",
    11: "Withdraw Adjustment",
  },
  lottoTransactionTypes: {
    44: "Reserve",
    45: "Cancel Reserve",
    46: "Credit",
  },
  adjustmentTypes: {
    deposit: "Addition",
    withdraw: "Subtraction",
  },
  bonusTriggerTypes: {
    1: "Deposit",
    2: "Manual",
    3: "Claim",
    4: "Retail Ticket",
  },
  bonusStatuses: {
    1: "Active",
    2: "Inactive",
    3: "Expired",
    4: "Forfeited",
    5: "Wagered",
    6: "Consumed",
    7: "Cancel System",
  },
  freeBetSources: {
    SportsbookSM: "Sportsbook",
    LiveBetting: "Sportsbook",
    Lotto: "Lotto",
  },
  happyHourEventTypes: {
    "online-slots": "Online Slots",
    "bets-prematch": "Bets Prematch",
    "bets-live": "Bets Live",
  },
  oktoDepositStatuses: {
    1: "Created",
    2: "Pending",
    3: "Executed",
    4: "Rejected",
  },
  skrillDepositStatuses: {
    "0": "Pending",
    "2": "Processed",
    "-1": "Cancelled",
    "-2": "Failed",
    "-3": "Chargeback",
  },
  aircashDepositStatuses: {
    "1": "Pending",
    "3": "Processed",
  },
};

export const themeConstants = {
  SET_DARK_MODE: "THEME_SET_DARK_MODE",
};

export const tagsThreeConstants = {
  TAGS_TREE_LOADED: "TAGS_TREE_LOADED",
};

export const bulkTagsGrant = {
  START: "BULK_TAGS_GRANT_START",
  DONE: "BULK_TAGS_GRANT_DONE",
  CLEAR: "BULK_TAGS_GRANT_CLEAR",
  PROGRESS: "BULK_TAGS_GRANT_PROGRESS",
  MAX_CONCURRENT_REQUESTS: 1, // MAX_CONCURRENT_REQUESTS = 1, because each request is already a batch of updates
  MAX_RETRIES: 3,
};

export const bulkBonusGrant = {
  START: "BULK_BONUS_GRANT_START",
  DONE: "BULK_BONUS_GRANT_DONE",
  CLEAR: "BULK_BONUS_GRANT_CLEAR",
  PROGRESS: "BULK_BONUS_GRANT_PROGRESS",
  MAX_CONCURRENT_REQUESTS: 10,
  MAX_RETRIES: 3,
};

export const bulkPrizeGrant = {
  START: "BULK_PRIZE_GRANT_START",
  DONE: "BULK_PRIZE_GRANT_DONE",
  CLEAR: "BULK_PRIZE_GRANT_CLEAR",
  PROGRESS: "BULK_PRIZE_GRANT_PROGRESS",
  MAX_CONCURRENT_REQUESTS: 10,
  MAX_RETRIES: 3,
};

export const bulkDeductionGrant = {
  START: "BULK_DEDUCTION_GRANT_START",
  DONE: "BULK_DEDUCTION_GRANT_DONE",
  CLEAR: "BULK_DEDUCTION_GRANT_CLEAR",
  PROGRESS: "BULK_DEDUCTION_GRANT_PROGRESS",
  MAX_CONCURRENT_REQUESTS: 10,
  MAX_RETRIES: 3,
};

export const bulkAffiliateInfoUpdate = {
  START: "BULK_AFFILIATE_INFO_UPDATE_START",
  DONE: "BULK_AFFILIATE_INFO_UPDATE_DONE",
  CLEAR: "BULK_AFFILIATE_INFO_UPDATE_CLEAR",
  UPDATE_REQUEST_MAX_LENGTH: "BULK_AFFILIATE_INFO_UPDATE_REQUEST_MAX_LENGTH",
  PROGRESS: "BULK_AFFILIATE_INFO_UPDATE_PROGRESS",
  IDLE_STATUS: "BULK_AFFILIATE_INFO_UPDATE_IDLE_STATUS",
  IN_PROGRESS_STATUS: "BULK_AFFILIATE_INFO_UPDATE_IN_PROGRESS_STATUS",
  DONE_STATUS: "BULK_AFFILIATE_INFO_UPDATE_DONE_STATUS",
  MAX_LENGTH_PLAYER_ID_LIST_PER_REQUEST: 300,
  UPDATED: "Updated",
  UNKNOWN: "Unknown",
  NOT_PROCESSED: "Not processed",
};

export const bulkWithdrawals = {
  START: "BULK_WITHDRAWAL_OPERATION_START",
  DONE: "BULK_WITHDRAWAL_OPERATION_DONE",
  CLEAR: "BULK_WITHDRAWAL_OPERATION_CLEAR",
  PROGRESS: "BULK_WITHDRAWAL_OPERATION_PROGRESS",
  MAX_CONCURRENT_REQUESTS: 10,
  MAX_RETRIES: 3,
};

export const rtNotificationsConstants = {
  RT_NOTIFICATION_RECEIVED: "RT_NOTIFICATION_RECEIVED",
  RT_NOTIFICATION_DISMISSED: "RT_NOTIFICATION_DISMISSED",
  RT_NOTIFICATION_CONFIRMED: "RT_NOTIFICATION_CONFIRMED",
  RT_NOTIFICATION_CLEAR: "RT_NOTIFICATION_CLEAR",
};

export const tableFiltersSorting = {
  TABLE_FILTER_SORTING_SET_DATA: "TABLE_FILTER_SORTING_SET_DATA",
  TABLE_FILTER_SORTING_SET_SELECTED: "TABLE_FILTER_SORTING_SET_SELECTED",
  TABLE_FILTER_SORTING_SET_DELETED: "TABLE_FILTER_SORTING_SET_DELETED",
  TABLE_FILTER_SORTING_SET_CHANGED: "TABLE_FILTER_SORTING_SET_CHANGED",
  TABLE_FILTER_SORTING_CLEAR: "TABLE_FILTER_SORTING_CLEAR",
};

export const templatizationEditPageConstants = {
  TEMPLATIZATION_SET_DATA: "TEMPLATIZATION_SET_DATA",
  TEMPLATIZATION_CLEAR_DATA: "TEMPLATIZATION_CLEAR_DATA",
};

export const sideMenuConstants = {
  SET: "SITE_MENU_SET",
};

export const dataConstants = {
  TYPESDEF_REQUEST: "DATA_TYPESDEF_REQUEST",
  TYPESDEF_RECEIVED: "DATA_TYPESDEF_RECEIVED",
};

export const contentManagementConstants = {
  // TYPESDEF_REQUEST: "CONTENT_MANAGEMENT_TYPESDEF_REQUEST",
  // TYPESDEF_RECEIVED: "CONTENT_MANAGEMENT_TYPESDEF_RECEIVED",
  DEFINITION_TYPES_REQUEST: "CONTENT_MANAGEMENT_DEFINITION_TYPES_REQUEST",
  DEFINITION_TYPES_RECEIVED: "CONTENT_MANAGEMENT_DEFINITION_TYPES_RECEIVED",
  ATTRIBUTES_LIST_REQUEST: "CONTENT_MANAGEMENT_ATTRIBUTES_LIST_REQUEST",
  ATTRIBUTES_LIST_RECEIVED: "CONTENT_MANAGEMENT_ATTRIBUTES_LIST_RECEIVED",
};

export const editorConstants = {
  SET_WIDTH_MEDIA: "EDITOR_SET_WIDTH_MEDIA",
  SET_PANEL_OPEN: "EDITOR_SET_PANEL_OPEN",
  SET_GROUP_STATE: "EDITOR_SET_GROUP_STATE",
  SET_PROPERTY_STATE: "EDITOR_SET_PROPERTY_STATE",
  SET_SELECTOR_STATE: "EDITOR_SET_SELECTOR_STATE",
  SET_MEDIA_QUERY_STATE: "EDITOR_SET_MEDIA_QUERY_STATE",
  SET_DELETE_MODE: "EDITOR_SET_DELETE_MODE",
  SET_DATA: "EDITOR_SET_DATA",
  CLEAR_PANELS: "EDITOR_CLEAR_PANELS",
  CLEAR: "EDITOR_CLEAR",
  CLEAR_ALL: "EDITOR_CLEAR_ALL",
  SET_EDITOR_STATES: "EDITOR_SET_EDITOR_STATES",
};

export const jackpotConstants = {
  SET_SELECTED_JACKPOT_SOURCES: "JACKPOT_SET_SELECTED_SOURCES",
  SET_JACKPOT_DATA: "JACKPOT_SET_JACKPOT_DATA",
  // SET_SOURCE_METADATA: "JACKPOT_SET_SOURCE_METADATA",
  // CLEAR_SOURCE_METADATA: "JACKPOT_CLEAR_SOURCE_METADATA",
  // SAVE_JACKPOT_SETUP: "JACKPOT_SAVE_JACKPOT_SETUP",
  // SAVE_JACKPOT_PARTICIPANTS: "JACKPOT_SAVE_JACKPOT_PARTICIPANTS",
  // SAVE_JACKPOT_ELIGIBILITY: "JACKPOT_SAVE_JACKPOT_ELIGIBILITY",
  // SAVE_JACKPOT_CONTRIBUTION: "JACKPOT_SAVE_JACKPOT_CONTRIBUTION",
};

export const loyaltyConstants = {
  SHOW_EDIT_MODAL: "LOYALTY_SHOW_EDIT_MODAL",
  HIDE_EDIT_MODAL: "LOYALTY_HIDE_EDIT_MODAL",
  SET_MODAL_STARTING_DATA: "LOYALTY_SET_MODAL_STARTING_DATA",
  SET_MODAL_DATA: "LOYALTY_SET_MODAL_DATA",
  INIT_PAGE: "LOYALTY_INIT_PAGE",
  SHOW_DIALOG: "LOYALTY_SHOW_DIALOG",
  HIDE_DIALOG: "LOYALTY_HIDE_DIALOG",
  SET_DIALOG_DATA: "LOYALTY_SET_DIALOG_DATA",
  CLONE_ENTITY: "LOYALTY_CLONE_ENTITY",

  //currencies
  LOAD_CURRENCY_TOTAL_COUNT: "LOYALTY_LOAD_CURRENCY_TOTAL_COUNT",
  LOADED_CURRENCY_TOTAL_COUNT: "LOYALTY_LOADED_CURRENCY_TOTAL_COUNT",
  LOAD_CURRENCIES: "LOYALTY_LOAD_CURRENCIES",
  LOADED_CURRENCIES: "LOYALTY_LOADED_CURRENCIES",
  LOAD_CURRENCY_GROUPS: "LOYALTY_LOAD_CURRENCY_GROUPS",
  LOADED_CURRENCY_GROUPS: "LOYALTY_LOADED_CURRENCY_GROUPS",
  SAVE_CURRENCY_GROUP: "LOYALTY_SAVE_CURRENCY_GROUP",
  SAVE_CURRENCY: "LOYALTY_SAVE_CURRENCY",

  //campaigns
  LOAD_CAMPAIGN_GROUPS: "LOYALTY_LOAD_CAMPAIGN_GROUPS",
  LOADED_CAMPAIGN_GROUPS: "LOYALTY_LOADED_CAMPAIGN_GROUPS",
  LOAD_CAMPAIGN_TOTAL_COUNT: "LOYALTY_LOAD_CAMPAIGN_TOTAL_COUNT",
  LOADED_CAMPAIGN_TOTAL_COUNT: "LOYALTY_LOADED_CAMPAIGN_TOTAL_COUNT",
  LOAD_CAMPAIGNS: "LOYALTY_LOAD_CAMPAIGNS",
  LOADED_CAMPAIGNS: "LOYALTY_LOADED_CAMPAIGNS",
  CREATE_CAMPAIGN: "LOYALTY_CREATE_CAMPAIGN",
  SAVE_CAMPAIGN_GROUP: "LOYALTY_SAVE_CAMPAIGN_GROUP",

  //rank_systems
  LOAD_RANK_SYSTEMS: "LOYALTY_LOAD_RANK_SYSTEMS",
  LOADED_RANK_SYSTEMS: "LOYALTY_LOADED_RANK_SYSTEMS",
  SAVE_RANK_CAMPAIGN_GROUP: "LOYALTY_SAVE_RANK_CAMPAIGN_GROUP",
  LOAD_RANK_CAMPAIGNS: "LOYALTY_LOAD_RANK_CAMPAIGNS",
  LOADED_RANK_CAMPAIGNS: "LOYALTY_LOADED_RANK_CAMPAIGNS",
  CREATE_RANK_CAMPAIGN: "LOYALTY_CREATE_RANK_CAMPAIGN",

  CREATE_LOYALTY_MISSION: "LOYALTY_CREATE_LOYALTY_MISSION",
  LOAD_LOYALTY_MISSIONS: "LOYALTY_LOAD_LOYALTY_MISSIONS",
  LOADED_LOYALTY_MISSIONS: "LOYALTY_LOADED_LOYALTY_MISSIONS",
  CREATE_LOYALTY_OBJECTIVE: "LOYALTY_CREATE_LOYALTY_OBJECTIVE",

  loyaltyTypes: {
    currencyGroup: "LOYALTY_TYPE_CURRENCY_GROUP",
    currency: "LOYALTY_TYPE_CURRENCY",
    campaignGroup: "LOYALTY_TYPE_CAMPAIGN_GROUP",
    campaign: "LOYALTY_TYPE_CAMPAIGN",
    rankTitle: "LOYALTY_TYPE_RANK_TITLE",
    rankCampaign: "LOYALTY_TYPE_RANK_CAMPAIGN",
    rankCampaignGroup: "LOYALTY_TYPE_RANK_CAMPAIGN_GROUP",
    mission: "LOYALTY_TYPE_MISSION",
    objective: "LOYALTY_TYPE_OBJECTIVE",
  },

  loyaltySubTypes: {
    none: "LOYALTY_SUB_TYPE_NONE",
    priority: "LOYALTY_SUB_TYPE_PRIORITY",
  }
};

export const tableSearchFilterConstants = {
  SET: "TABLE_SEARCH_SET",
  CLEAR: "TABLE_SEARCH_CLEAR",
  SET_SEARCH_VALUE: "TABLE_SEARCH_SET_SEARCH_VALUE",
  DO_SEARCH: "TABLE_SEARCH_DO_SEARCH",
  LAST_REQUEST_SENT: "TABLE_SEARCH_LAST_REQUEST_SENT",
  SET_NEW_SEARCH_PENDING: "TABLE_SEARCH_SET_NEW_SEARCH_PENDING",
  DO_QUICK_SEARCH: "TABLE_SEARCH_DO_QUICK_SEARCH",
  SET_QUICK_SEARCH_RESULTS: "TABLE_SEARCH_SET_QUICK_SEARCH_RESULTS",
  SET_SEARCH_LAST_VISITED_RESULT: "TABLE_SEARCH_SET_LAST_VISITED_RESULT",
  SAVE_SEARCH_LAST_VISITED_RESULT: "TABLE_SEARCH_SAVE_LAST_VISITED_RESULT",
  GET_SEARCH_LAST_VISITED_RESULT: "TABLE_SEARCH_GET_LAST_VISITED_RESULT",
  FILTER_DO_NEW_SEARCH: "TABLE_SEARCH_FILTER_DO_NEW_SEARCH",
  STORE_FILTER_LAST_SEARCH: "TABLE_SEARCH_STORE_FILTER_LAST_SEARCH",
  SAVE_FILTER_LAST_SEARCH: "TABLE_SEARCH_SAVE_FILTER_LAST_SEARCH",
  LOAD_FILTER_LAST_SEARCH: "TABLE_SEARCH_LOAD_FILTER_LAST_SEARCH",
  STORE_FILTER_PRESET_DEFINITION: "TABLE_SEARCH_STORE_FILTER_PRESET_DEFINITION",
  STORED_FILTER_PRESET_DEFINITION: "TABLE_SEARCH_STORED_FILTER_PRESET_DEFINITION",
  CLEAR_FILTER_PRESET_DEFINITION_MODAL: "TABLE_SEARCH_CLEAR_FILTER_PRESET_DEFINITION_MODAL",
  LOAD_ALL_FILTER_PRESET_DEFINITIONS: "TABLE_SEARCH_LOAD_ALL_FILTER_PRESET_DEFINITIONS",
  SET_ALL_FILTER_PRESET_DEFINITIONS: "TABLE_SEARCH_SET_ALL_FILTER_PRESET_DEFINITIONS",
  UPDATE_STORED_ALL_FILTER_PRESET_DEFINITIONS: "TABLE_SEARCH_UPDATE_STORED_ALL_FILTER_PRESET_DEFINITIONS",
  EDIT_FILTER_PRESET_DEFINITION: "TABLE_SEARCH_EDIT_FILTER_PRESET_DEFINITION",
  SET_FILTER_SEARCH_ACTIVE_PRESET: "TABLE_SEARCH_SET_FILTER_SEARCH_ACTIVE_PRESET",
  SET_FILTER_INCOMING_ACTIVE_PRESET: "TABLE_SEARCH_SET_FILTER_INCOMING_ACTIVE_PRESET",
};

export const druidConstants = {
  LOAD: "DRUID_LOAD",
  LOADED: "DRUID_LOADED",
};

export const notificationTemplatesConstants = {
  LOADING_VALUES: "NOTIFICATION_TEMPLATES_LOADING_VALUES",
  LOAD_VALUES: "NOTIFICATION_TEMPLATES_LOAD_VALUES",
  LOADED_VALUES: "NOTIFICATION_TEMPLATES_LOADED_VALUES",
  CLEAR: "NOTIFICATION_TEMPLATES_CLEAR",
};

export const selectorsConstants = {
  SET_DATA: "SELECTORS_SET_DATA",
  CLEAR_ALL: "SELECTORS_CLEAR_ALL",
};

export const countryFlagsConstants = {
  LOADING: "COUNTRY_FLAGS_LOADING",
  LOAD: "COUNTRY_FLAGS_LOAD",
  LOADED: "COUNTRY_FLAGS_LOADED",
};

export const adminSettingsConstants = {
  LOADING: "ADMIN_SETTINGS_LOADING",
  LOAD: "ADMIN_SETTINGS_LOAD",
  LOADED: "ADMIN_SETTINGS_LOADED",
};