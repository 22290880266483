import React, { lazy } from "react";

// ** Document title
const TemplateTitle = "%s - Vuexy React Admin Template";

// ** Default Route
const DefaultRoute = `${window.ini.REACT_APP_ROOT_PATH}/home`;

//import Login from "../../../views/Login";
import Login from "../../views/Login";
import Home from "../../../views/Home";

const Sports = React.lazy(() => import("../../../views/Sports"));
const UKSettingsMarkets = React.lazy(() => import("../../../views/uk-settings/markets"));
const Crests = React.lazy(() => import("../../../views/Crests"));
const Highlights = React.lazy(() => import("../../../views/Highlights"));
const NotFound = React.lazy(() => import("../../../views/NotFound"));
const Entities = React.lazy(() => import("../../../views/Entities"));
const CalendarHot = React.lazy(() => import("../../../views/CalendarHot"));
const Bets = React.lazy(() => import("../../../views/Bets"));
const Outcomes = React.lazy(() => import("../../../views/Outcomes"));
const SportRadar = React.lazy(() => import("../../../views/SportRadar"));
const Settings = React.lazy(() => import("../../../views/Settings"));

const HomepageLeagues = React.lazy(() => import("../../../views/HomepageLeagues"));
const HomepageNews = React.lazy(() => import("../../../views/HomepageNews"));
const HomepageSports = React.lazy(() => import("../../../views/HomepageSports"));
const CustomizeNavBar = React.lazy(() => import("../../../views/CustomizeNavBar"));
const LiveTopLeague = React.lazy(() => import("../../../views/LiveTopLeague"));
const ErrorLogs = React.lazy(() => import("../../../views/ErrorLogs"));
const AuditLogs = React.lazy(() => import("../../../views/AuditLogs"));
const LottoPromoted = React.lazy(() => import("../../../views/LottoPromoted"));
const HomepageGroups = React.lazy(() => import("../../../views/HomepageGroups"));
const HomepageGroupsImages = React.lazy(() => import("../../../views/HomepageGroupsImages"));
const BonusEvaluate = React.lazy(() => import("../../../views/BonusEvaluate"));
const BonusTicketBuilder = React.lazy(() => import("../../../views/BonusTicketBuilder"));
const BonusTicketList = React.lazy(() => import("../../../views/BonusTicketList"));
const BonusTicketsSettled = React.lazy(() => import("../../../views/BonusTicketsSettled"));
const BonusTicketsOpened = React.lazy(() => import("../../../views/BonusTicketsOpened"));
const BonusRulesList = React.lazy(() => import("../../../views/BonusRulesList"));

const DigitainPlayersList = React.lazy(() => import("../../../views/digitain/DigitainPlayers"));
const DigitainTicketsList = React.lazy(() => import("../../../views/digitain/DigitainTickets"));
const DigitainTicketInfo = React.lazy(() => import("../../../views/digitain/components/DigitainOrderNumberInfo"));
const DigitainTransactionsList = React.lazy(() => import("../../../views/digitain/DigitainTicketsTransactions"));
const DigitainInitialDataList = React.lazy(() => import("../../../views/digitain/DigitainInitialDataList"));

const DigitainWinnerFunPlayersList = React.lazy(() => import("../../../views/digitain/DigitainWinnerFunPlayers"));
const DigitainWinnerFunTicketsList = React.lazy(() => import("../../../views/digitain/DigitainWinnerFunTickets"));
const DigitainWinnerFunTicketInfo = React.lazy(() =>
  import("../../../views/digitain/components/DigitainOrderNumberInfo")
);
const DigitainWinnerFunTicketsTransactionsList = React.lazy(() =>
  import("../../../views/digitain/DigitainWinnerFunTicketsTransactions")
);
const HappyHoursDefinitions = React.lazy(() => import("../../../views/HappyHoursDefinitions"));
const HappyHoursEvents = React.lazy(() => import("../../../views/HappyHoursEvents"));
const HappyHoursDefinitionEdit = React.lazy(() => import("../../../views/HappyHoursDefinitionEdit"));
const HappyHoursAwards = React.lazy(() => import("../../../views/HappyHoursAwards"));

const SuperBannerCasino = React.lazy(() => import("../../../views/SuperBannerCasino"));
const SuperBannerLotto = React.lazy(() => import("../../../views/SuperBannerLotto"));
const SuperBannerVirtual = React.lazy(() => import("../../../views/SuperBannerVirtual"));

const Components = React.lazy(() => import("../../../views/Components"));
const ComponentEdit = React.lazy(() => import("../../../views/ComponentEdit"));

const SlotsBGColor = React.lazy(() => import("../../../views/SlotsBGColor"));
const Users = React.lazy(() => import("../../../views/Users"));

const NewMessage = React.lazy(() => import("../../../views/NewMessage"));
const PlayerMessages = React.lazy(() => import("../../../views/PlayerMessages"));
const Notifications = React.lazy(() => import("../../../views/Notifications"));

const Maintenance = React.lazy(() => import("../../../views/Maintenance"));

const WinnerFunCalendar = React.lazy(() => import("../../../views/WinnerFunCalendar"));
const WinnerFunCalendarEdit = React.lazy(() => import("../../../views/WinnerFunCalendarEdit"));
const WinnerFunWinCoinsSystems = React.lazy(() => import("../../../views/WinnerFunWinCoinsSystems"));
const WinnerFunWinCoinsSystemsEdit = React.lazy(() => import("../../../views/WinnerFunWinCoinsSystemsEdit"));
const WinnerFunNicknames = React.lazy(() => import("../../../views/WinnerFunNicknames"));
const WinnerFunCalendarRanking = React.lazy(() => import("../../../views/WinnerFunCalendarRanking"));
const WinnerFunReferralSettings = React.lazy(() => import("../../../views/WinnerFunReferralSettings"));
const WinnerFunRankingSettings = React.lazy(() => import("../../../views/WinnerFunRankingSettings"));
const WinnerFunSuperBanner = React.lazy(() => import("../../../views/SuperBannerWinnerFun"));
const WinnerFunClaimReport = React.lazy(() => import("../../../views/WinnerFunClaimReport"));
const WinnerFunRAFReport = React.lazy(() => import("../../../views/WinnerFunRAFReport"));

const WinnerFunTeamsUI = React.lazy(() => import("../../../views/winner-fun/TeamsUI"));
const WinnerFunTeamsData = React.lazy(() => import("../../../views/winner-fun/TeamsData"));
const WinnerFunTeamDataEdit = React.lazy(() => import("../../../views/winner-fun/TeamDataEdit"));
const WinnerFunTeamsRanking = React.lazy(() => import("../../../views/WinnerFunTeamsRanking"));
const WinnerFunTeamRanking = React.lazy(() => import("../../../views/WinnerFunTeamRanking"));

const BonusesTagClasses = React.lazy(() => import("../../../views/bonuses/TagClasses"));
const BonusesFreeBets = React.lazy(() => import("../../../views/bonuses/FreeBetPreset"));
const BonusesFreeBetEdit = React.lazy(() => import("../../../views/bonuses/FreeBetPresetEdit"));
const BonusesPopups = React.lazy(() => import("../../../views/bonuses/BonusPopups"));
const BonusesPopupEdit = React.lazy(() => import("../../../views/bonuses/BonusPopupEdit"));

const EnrollmentCampaigns = React.lazy(() => import("../../../views/bonuses/EnrollmentCampaigns"));
const EnrollmentCampaignsEdit = React.lazy(() => import("../../../views/bonuses/EnrollmentCampaignsEdit"));

const Campaigns = React.lazy(() => import("../../../views/bonuses/Campaigns"));
const CampaignsEdit = React.lazy(() => import("../../../views/bonuses/CampaignsEdit"));

const Games = React.lazy(() => import("../../../views/casino/Games"));
const GamesEdit = React.lazy(() => import("../../../views/casino/GamesEdit"));
const GamesAttributes = React.lazy(() => import("../../../views/casino/GamesAttributes"));
const GamesAttributesEdit = React.lazy(() => import("../../../views/casino/GamesAttributesEdit"));
const GamesDataSources = React.lazy(() => import("../../../views/casino/GamesDataSources"));
const GameDataSourcesEdit = React.lazy(() => import("../../../views/casino/GamesDataSourcesEdit"));

const LobbyDataSources = React.lazy(() => import("../../../views/lobbyV2/data-sources"));
const LobbyDataSourcesEdit = React.lazy(() => import("../../../views/lobbyV2/data-sources-edit"));
const LobbyPages = React.lazy(() => import("../../../views/lobbyV2/pages"));
const LobbyPagesEdit = React.lazy(() => import("../../../views/lobbyV2/pages-edit"));
const LobbyComponentTemplates = React.lazy(() => import("../../../views/lobbyV2/component-templates"));
const LobbyComponentTemplatesEdit = React.lazy(() => import("../../../views/lobbyV2/component-templates-edit"));

const WheelProducts = React.lazy(() => import("../../../views/wheel/Products"));
const WheelProductEdit = React.lazy(() => import("../../../views/wheel/ProductEdit"));
const WheelValues = React.lazy(() => import("../../../views/wheel/Values"));
const WheelMultipliers = React.lazy(() => import("../../../views/wheel/Multipliers"));
const WheelPuzzles = React.lazy(() => import("../../../views/wheel/Puzzles"));
const WheelSetups = React.lazy(() => import("../../../views/wheel/Setups"));
const WheelSetupsEdit = React.lazy(() => import("../../../views/wheel/SetupsEdit"));
const WheelPlayerLevelSetups = React.lazy(() => import("../../../views/wheel/PlayerLevelSetups"));
const WheelPlayerLevelSetupsEdit = React.lazy(() => import("../../../views/wheel/PlayerLevelSetupsEdit"));
const WheelSettings = React.lazy(() => import("../../../views/wheel/Settings"));
const WheelPrizes = React.lazy(() => import("../../../views/wheel/Prizes"));
const WheelCoreEligibility = React.lazy(() => import("../../../views/wheel/CoreEligibility"));
const WheelProbabilityRanges = React.lazy(() => import("../../../views/wheel/ProbabilityRanges"));
const WheelProbabilityRangesEdit = React.lazy(() => import("../../../views/wheel/ProbabilityRangesEdit"));
const WheelPlayerKeys = React.lazy(() => import("../../../views/wheel/PlayerWheelKeys"));
const WheelPlayerTokens = React.lazy(() => import("../../../views/wheel/PlayerWheelTokens"));

const PlayerWheelSpins = React.lazy(() => import("../../../views/wheel/PlayerWheelSpins"));
const AggWheelSpins = React.lazy(() => import("../../../views/wheel/AggWheelSpins"));

const OptimoveCampaignTags = React.lazy(() => import("../../../views/optimove/CampaignTags"));
const OptimoveScheduledRuns = React.lazy(() => import("../../../views/optimove/ScheduledRuns"));
const OptimoveTaggedPlayers = React.lazy(() => import("../../../views/optimove/TaggedPlayers"));

const PromoWinter = React.lazy(() => import("../../../views/promo/WinterPromo"));
const PromoWinterEdit = React.lazy(() => import("../../../views/promo/WinterPromoEdit"));
const PromoWinterPrizes = React.lazy(() => import("../../../views/promo/WinterPromoPrizes"));
const PromoSettings = React.lazy(() => import("../../../views/promo/Settings"));

const LandingPages = React.lazy(() => import("../../../views/landing-pages/pages"));
const LandingPagesEdit = React.lazy(() => import("../../../views/landing-pages/pages-edit"));
const LandingPagesMarketingOffers = React.lazy(() => import("../../../views/landing-pages/market-offerings"));
const LandingPagesMarketingOffersEdit = React.lazy(() => import("../../../views/landing-pages/market-offering-edit"));

const MissionsTournamentsMissionsTemplates = React.lazy(() =>
  import("../../../views/missions-tournaments/missions-templates")
);
const MissionsTournamentsTemplateMissionEdit = React.lazy(() =>
  import("../../../views/missions-tournaments/mission-template-edit")
);
const MissionsTournamentsMissions = React.lazy(() => import("../../../views/missions-tournaments/missions"));
const MissionsTournamentsMissionEdit = React.lazy(() => import("../../../views/missions-tournaments/mission-edit"));
const MissionsTournamentsPlayerMissions = React.lazy(() =>
  import("../../../views/missions-tournaments/player-missions")
);

const CustomerAccountsPlayerMissions = React.lazy(() =>
  import("src/views-core-admin/player-missions/player-missions-search.js")
);

const MissionsTournamentsObjectivesTemplates = React.lazy(() =>
  import("../../../views/missions-tournaments/objectives-templates")
);
const MissionsTournamentsObjectiveTemplateEdit = React.lazy(() =>
  import("../../../views/missions-tournaments/objective-template-edit")
);
const MissionsTournamentsObjectives = React.lazy(() => import("../../../views/missions-tournaments/objectives"));
const MissionsTournamentsObjectiveEdit = React.lazy(() => import("../../../views/missions-tournaments/objective-edit"));

const MissionsTournamentsMissionRanking = React.lazy(() =>
  import("../../../views/missions-tournaments/mission_ranking")
);

const MissionsTournamentsMissionsRanking = React.lazy(() =>
  import("../../../views/missions-tournaments/missions-ranking")
);
const MissionsTournamentsMissionsRankingView = React.lazy(() =>
  import("../../../views/missions-tournaments/missions-ranking-view")
);

const MissionsTournamentsTournamentGroups = React.lazy(() =>
  import("../../../views/missions-tournaments/tournament-groups")
);
const MissionsTournamentsTournamentGroupEdit = React.lazy(() =>
  import("../../../views/missions-tournaments/tournament-groups-edit")
);

const NotificationTemplatesSearch = React.lazy(() =>
  import("../../../views/notification-templates/notification-templates-search")
);
const NotificationsSearch = React.lazy(() => import("../../../views/notification-templates/notifications-search"));
const NotificationTemplatesEdit = React.lazy(() =>
  import("../../../views/notification-templates/notification-template-edit")
);

const MainSettingsSeach = React.lazy(() => import("../../../views/main-settings/main-settings-search"));
const MainSettingsEdit = React.lazy(() => import("../../../views/main-settings/main-settings-edit"));

const LoyaltyLoyaltyCurrencyManager = React.lazy(() => import("../../../views/loyalty/loyalty-currency-manager"));
const LoyaltyCurrencySortingGroupsEdit = React.lazy(() =>
  import("../../../views/loyalty/loyalty-currency-manager/loyalty-currency-sorting-groups-edit")
);
const LoyaltyLoyaltyCurrencyManagerCurrencies = React.lazy(() =>
  import("../../../views/loyalty/loyalty-currency-manager-currencies")
);
const LoyaltyLoyaltyCurrencyGroupEdit = React.lazy(() =>
  import("../../../views/loyalty/loyalty-currency-manager-group-edit")
);
const LoyaltyLoyaltyCurrencyEdit = React.lazy(() =>
  import("../../../views/loyalty/loyalty-currency-manager-currency-edit")
);

const LoyaltyCampaignGroupManager = React.lazy(() => import("../../../views/loyalty/loyalty-campaign-groups-manager"));
const LoyaltyCampaignManager = React.lazy(() => import("../../../views/loyalty/loyalty-campaigns-manager"));

const LoyaltyRankCampaignGroupManager = React.lazy(() =>
  import("../../../views/loyalty/loyalty-rank-campaign-groups-manager")
);
const LoyaltyRankCampaignGroupEdit = React.lazy(() =>
  import("../../../views/loyalty/loyalty-rank-systems/loyalty-rank-campaign-group-edit")
);
const LoyaltyRankCampaignManager = React.lazy(() => import("../../../views/loyalty/loyalty-rank-campaign-manager"));
const LoyaltyRankCampaignEdit = React.lazy(() =>
  import("../../../views/loyalty/loyalty-rank-systems/loyalty-rank-campaign-edit")
);

const LoyaltyLoyaltyCampaignGroupEdit = React.lazy(() =>
  import("../../../views/loyalty/loyalty-campaign-manager/loyalty-campaign-group-edit")
);

const LoyaltyLoyaltyCampaignEdit = React.lazy(() =>
  import("../../../views/loyalty/loyalty-campaign-manager/loyalty-campaign-edit")
);

const LoyaltyLoyaltyMissions = React.lazy(() => import("../../../views/loyalty/loyalty-missions"));

const LoyaltyLoyaltyMission = React.lazy(() => import("../../../views/loyalty/loyalty-mission-edit"));

const LoyaltyLoyaltyObjectives = React.lazy(() => import("../../../views/loyalty/loyalty-objectives"));

const LoyaltyLoyaltyObjective = React.lazy(() =>
  import("../../../views/loyalty/loyalty-objectives/loyalty-objective-edit")
);

const MissionTournamentsLoyaltyCampaignGroups = React.lazy(() =>
  import("../../../views/missions-tournaments/loyalty-campaign-groups")
);
const MissionTournamentsLoyaltyCampaignGroupEdit = React.lazy(() =>
  import("../../../views/missions-tournaments/loyalty-campaign-group-edit")
);
const MissionTournamentsLoyaltyRankSystems = React.lazy(() =>
  import("../../../views/missions-tournaments/loyalty-rank-systems")
);
const MissionTournamentsLoyaltyRankTitles = React.lazy(() =>
  import("../../../views/missions-tournaments/loyalty-rank-titles")
);
const MissionTournamentsLoyaltyRankTitleEdit = React.lazy(() =>
  import("src/views/missions-tournaments/loyalty-rank-systems/rank-title-edit")
);
const MissionTournamentsLoyaltyRankCampaignEdit = React.lazy(() =>
  import("src/views/missions-tournaments/loyalty-rank-systems/rank-campaign-edit")
);

const MediaLibrary = React.lazy(() => import("../../../views/media-library/images"));

const VideoReels = React.lazy(() => import("../../../views/reels-media-library/video-reels-search"));

const PlayersAvatars = React.lazy(() => import("../../../views/players/avatars"));

const PlayerHistory = React.lazy(() => import("../../../views-core-admin/player-timeline/PlayerHistory"));

const EvolutionCampaigns = React.lazy(() => import("../../../views/evolution/campaigns"));
const EvolutionCampaignEdit = React.lazy(() => import("../../../views/evolution/campaign-edit"));

const LottoRaspberryPI = React.lazy(() => import("../../../views/LottoRaspberryPi"));

const RetailTickets = React.lazy(() => import("../../../views/retail/tickets"));
const RetailTicketsSettings = React.lazy(() => import("../../../views/retail/settings"));

// migratedold admin pages
const PaymentsSearch = React.lazy(() => import("../../../views-core-admin/payments/payments-search"));
const Payment = React.lazy(() => import("../../../views-core-admin/payments/payment"));
const PaymentsTaxReport = React.lazy(() => import("../../../views-core-admin/payments-tax-report/payments-tax-report"));
const PaymentsWinnerFunReport = React.lazy(() =>
  import("../../../views-core-admin/payments-winner-fun-report/payments-winner-fun-report")
);

const BonusSearch = React.lazy(() => import("../../../views-core-admin/bonus/bonus-search"));
const PlayerBonus = React.lazy(() => import("../../../views-core-admin/bonus/player-bonus"));

const PlayersSearch = React.lazy(() => import("../../../views-core-admin/players/players-search"));
const PlayerOverview = React.lazy(() => import("../../../views-core-admin/player-overview/player-overview"));
const PlayerBonusSearch = React.lazy(() => import("../../../views-core-admin/bonus/player-bonus-search"));
const PlayerPaymentSearch = React.lazy(() => import("../../../views-core-admin/payments/player-payments-search"));
const PlayerPrizes = React.lazy(() => import("../../../views-core-admin/player-prizes/player-prizes"));
const PlayerPrizesDetails = React.lazy(() => import("../../../views-core-admin/player-prizes/player-prizes-details"));
const PlayerCardsSearch = React.lazy(() =>
  import("../../../views-core-admin/player-payment-methods/player-payment-methods")
);
const PlayerLoginHistorySearch = React.lazy(() =>
  import("../../../views-core-admin/player-login-history/player-login-history")
);
const PlayerDocumentsSearch = React.lazy(() => import("../../../views-core-admin/player-documents/player-documents"));
const PlayerDocumentHistory = React.lazy(() =>
  import("../../../views-core-admin/player-documents/player-document-history")
);
const PlayerTimeline = React.lazy(() => import("../../../views-core-admin/player-timeline/player-timeline"));
const PlayerAccountManagement = React.lazy(() =>
  import("../../../views-core-admin/player-account-management/player-account-management")
);
const PlayerTags = React.lazy(() => import("../../../views-core-admin/player-tags/player-tags"));
const PlayerTagHistory = React.lazy(() => import("../../../views-core-admin/player-tags/player-tags-history"));
const PlayerFraud = React.lazy(() => import("../../../views-core-admin/player-fraud/player-fraud"));
const PlayerGamesActivity = React.lazy(() =>
  import("../../../views-core-admin/player-games-activity/player-games-activity")
);
const PlayerGamesActivityDetails = React.lazy(() =>
  import("../../../views-core-admin/player-games-activity/player-game-activity-details")
);
const PlayerComments = React.lazy(() => import("../../../views-core-admin/player-comments/player-comments"));
const PlayerWallets = React.lazy(() => import("../../../views-core-admin/player-wallets/player-wallets"));
const PlayerVouchers = React.lazy(() => import("../../../views-core-admin/player-vouchers/player-vouchers"));
const PlayerVoucherDetails = React.lazy(() =>
  import("../../../views-core-admin/player-vouchers/player-voucher-details")
);
const PlayerLoyalty = React.lazy(() => import("../../../views-core-admin/player-loyalty/player-loyalty"));
const PlayerInbox = React.lazy(() => import("../../../views-core-admin/player-inbox/player-inbox"));
const PlayerRestrictionsAndLimits = React.lazy(() =>
  import("../../../views-core-admin/player-restrictions-and-limits/player-restrictions-and-limits")
);
const PlayerDeviceSearch = React.lazy(() => import("../../../views-core-admin/player-device/player-device-search"));

const PlayerInfo = React.lazy(() => import("../../../views-core-admin/player-overview/player-overview-player-info"));
const PlayerRegistrationDetails = React.lazy(() =>
  import("../../../views-core-admin/player-overview/player-overview-registration-details")
);

const TransactionsSearch = React.lazy(() => import("../../../views-core-admin/transactions/transactions-search"));
const PlayerTransactionsSearch = React.lazy(() =>
  import("../../../views-core-admin/transactions/player-transactions-search")
);
const CronLogsSearch = React.lazy(() => import("../../../views-core-admin/onjn/cron-logs/cron-logs-search"));
const GameTypeSearch = React.lazy(() => import("../../../views-core-admin/onjn/game-type/game-type-search"));
const GameProfitSearch = React.lazy(() => import("../../../views-core-admin/onjn/game-profit/game-profit-search"));
const JackpotWinnings = React.lazy(() =>
  import("../../../views-core-admin/onjn/jackpot-winnings/jackpot-winnings-search")
);
const RelevantEvents = React.lazy(() =>
  import("../../../views-core-admin/onjn/relevant-events/relevant-events-search")
);
const ExclusionReport = React.lazy(() =>
  import("../../../views-core-admin/onjn/exclusion-report/exclusion-report-search")
);
const WalletAdjustment = React.lazy(() =>
  import("../../../views-core-admin/onjn/wallet-adjustment/wallet-adjustment-search")
);
const Promotions = React.lazy(() => import("../../../views-core-admin/onjn/promotions/promotions-search"));
const GameSessions = React.lazy(() => import("../../../views-core-admin/onjn/game-sessions/game-sessions-search"));
const ReportExport = React.lazy(() => import("../../../views-core-admin/onjn/report-export/report-export-search"));
const RequestedReports = React.lazy(() =>
  import("../../../views-core-admin/onjn/requested-reports/requested-reports-search")
);
const OnjnSignAuthThankYou = React.lazy(() =>
  import("../../../views-core-admin/onjn/onjn-report-request-sign-auth-thank-you")
);

const BackupFiles = React.lazy(() => import("../../../views-core-admin/income-access/backup-files/backup-files"));
const UploadLog = React.lazy(() => import("../../../views-core-admin/income-access/upload-log/upload-log"));

const AclUsersList = React.lazy(() => import("../../../acl/user/acl-users-list"));
const AclUserDetails = React.lazy(() => import("../../../acl/user/acl-user-details"));
const AclGroupsList = React.lazy(() => import("../../../acl/users-groups/acl-users-groups-list"));
const AclGroupDetails = React.lazy(() => import("../../../acl/users-groups/acl-users-groups-details"));
const AclPermissions = React.lazy(() => import("../../../acl/permissions/acl-permissions"));
const AclRoles = React.lazy(() => import("../../../acl/roles/acl-roles-list"));
const AclRoleDetails = React.lazy(() => import("../../../acl/roles/acl-role-details"));
const AclRulesList = React.lazy(() => import("../../../acl/rules/acl-rules-list"));
const AclRuleDetails = React.lazy(() => import("../../../acl/rules/acl-rules-details"));
const AclTenants = React.lazy(() => import("../../../acl/tenants/acl-tenants-list"));
const AclTenantsGeneralSettings = React.lazy(() => import("../../../acl/tenants/acl-tenants-general-settings"));
const AclApiTokenList = React.lazy(() => import("../../../acl/api-token/acl-api-token-list"));
const AclApiTokenDetails = React.lazy(() => import("../../../acl/api-token/acl-api-token-details"));
const AclEntityGroupsList = React.lazy(() => import("../../../acl/entity-groups/acl-entity-groups-list"));
const AclEntityGroupsDetails = React.lazy(() => import("../../../acl/entity-groups/acl-entity-groups-details"));
const AclLocationsList = React.lazy(() => import("../../../acl/locations/acl-locations-list"));
const AclLocationsDetails = React.lazy(() => import("../../../acl/locations/acl-locations-details"));
const AclSitesList = React.lazy(() => import("../../../acl/sites/acl-sites-list"));
const AclSiteDetails = React.lazy(() => import("../../../acl/sites/acl-sites-details"));
const AclDomainsList = React.lazy(() => import("../../../acl/domains/acl-domains-list"));
const AclDomainDetail = React.lazy(() => import("../../../acl/domains/acl-domain-settings"));

const SettingsCurrencyList = React.lazy(() => import("../../../views/general-settings/currency-list"));
const SettingsCurrencyEdit = React.lazy(() => import("../../../views/general-settings/currency-edit"));
const SettingsCountryList = React.lazy(() => import("../../../views/general-settings/country-list"));
const SettingsCountryEdit = React.lazy(() => import("../../../views/general-settings/country-edit"));
const SettingsGeoRegionList = React.lazy(() => import("../../../views/general-settings/geo-region-list"));
const SettingsGeoRegionEdit = React.lazy(() => import("../../../views/general-settings/geo-region-edit"));
const SettingsGameProviderList = React.lazy(() => import("../../../views/general-settings/game-provider-list"));
const SettingsGameProviderEdit = React.lazy(() => import("../../../views/general-settings/game-provider-edit"));
const SettingsCasinoGameList = React.lazy(() => import("../../../views/general-settings/casino-game-list"));
const SettingsCasinoGameEdit = React.lazy(() => import("../../../views/general-settings/casino-game-edit"));
const SettingsAttributesList = React.lazy(() => import("../../../views/general-settings/attributes-list"));
const SettingsAttributesEdit = React.lazy(() => import("../../../views/general-settings/attributes-edit"));
const SettingsArtBundleTemplatesList = React.lazy(() =>
  import("../../../views/general-settings/art-bundle-template-list")
);
const SettingsArtBundleTemplatesEdit = React.lazy(() =>
  import("../../../views/general-settings/art-bundle-template-edit")
);
const SettingsBannerList = React.lazy(() => import("../../../views/general-settings/banner-list"));
const SettingsBannerEdit = React.lazy(() => import("../../../views/general-settings/banner-edit"));
const SettingsPromotionList = React.lazy(() => import("../../../views/general-settings/promotion-list"));
const SettingsPromotionEdit = React.lazy(() => import("../../../views/general-settings/promotion-edit"));
const SettingsTermsOfServiceList = React.lazy(() => import("../../../views/general-settings/terms-of-service-list"));
const SettingsTermsOfServiceEdit = React.lazy(() => import("../../../views/general-settings/terms-of-service-edit"));
const SettingsShopItemList = React.lazy(() => import("../../../views/general-settings/shop-item-list"));
const SettingsShopItemEdit = React.lazy(() => import("../../../views/general-settings/shop-item-edit"));
const SettingsArtBundlesList = React.lazy(() => import("../../../views/general-settings/art-bundles-list"));
const SettingsArtBundleEdit = React.lazy(() => import("../../../views/general-settings/art-bundles-edit"));
const SettingsBonusPresetList = React.lazy(() => import("../../../views/general-settings/bonus/bonus-preset"));
const SettingsBonusPresetEdit = React.lazy(() => import("../../../views/general-settings/bonus/bonus-preset-edit"));
const SettingsBonusList = React.lazy(() => import("../../../views/general-settings/bonus/bonus-list"));
const SettingsPrizeTemplateList = React.lazy(() => import("../../../views/general-settings/prize-template-list"));
// const SettingsPrizeTemplateEdit = React.lazy(() => import("../../../views/general-settings/prize-template-edit"));
const SettingsPrizeTemplateEdit = React.lazy(() => import("../../../views/general-settings/prize-template-edit-new"));
const SettingsHappyHourDefinitionsList = React.lazy(() =>
  import("../../../views/general-settings/happy-hour/HappyHoursDefinitions")
);
const SettingsHappyHourDefinitionsEdit = React.lazy(() =>
  import("../../../views/general-settings/happy-hour/HappyHoursDefinitionEdit")
);
const SettingsJackpotGroupsList = React.lazy(() => import("../../../views/general-settings/jackpot-group-list"));
const SettingsJackpotGroupsEdit = React.lazy(() => import("../../../views/general-settings/jackpot-group-edit"));

const SettingsBannerTemplateList = React.lazy(() => import("../../../views/general-settings/banner-template-list"));
const SettingsBannerTemplateEdit = React.lazy(() => import("../../../views/general-settings/banner-template-edit"));

const SettingsPromotionTemplateList = React.lazy(() =>
  import("../../../views/general-settings/promotion-template-list")
);
const SettingsPromotionTemplateEdit = React.lazy(() =>
  import("../../../views/general-settings/promotion-template-edit")
);

const SettingsShopItemTemplateList = React.lazy(() =>
  import("../../../views/general-settings/shop-item-template-list")
);
const SettingsShopItemTemplateEdit = React.lazy(() =>
  import("../../../views/general-settings/shop-item-template-edit")
);

const SettingsMissionsObjectivesList = React.lazy(() =>
  import("../../../views/general-settings/missions/objectives-templates-list")
);
const SettingsMissionsObjectivesEdit = React.lazy(() =>
  import("../../../views/general-settings/missions/objectives-templates-edit")
);
const SettingsMissionsTemplatesList = React.lazy(() =>
  import("../../../views/general-settings/missions/missions-templates-list")
);
const SettingsMissionsTemplatesEdit = React.lazy(() =>
  import("../../../views/general-settings/missions/missions-templates-edit")
);
const SettingsWheelSetupsTemplatesList = React.lazy(() =>
  import("../../../views/general-settings/wheel/setups-templates-list")
);
const SettingsWheelSetupsTemplatesEdit = React.lazy(() =>
  import("../../../views/general-settings/wheel/setups-templates-edit")
);
const SettingsWheelProductsTemplatesList = React.lazy(() =>
  import("../../../views/general-settings/wheel/products-templates-list")
);
const SettingsWheelValuesTemplatesList = React.lazy(() =>
  import("../../../views/general-settings/wheel/values-templates-list")
);
const SettingsWheelMultipliersTemplatesList = React.lazy(() =>
  import("../../../views/general-settings/wheel/multipliers-templates-list")
);
const SettingsWheelPuzzlesTemplatesList = React.lazy(() =>
  import("../../../views/general-settings/wheel/puzzles-templates.list")
);
const SettingsRewardBundlesList = React.lazy(() => import("../../../views/loyalty/reward-bundles-list"));
const SettingsRewardBundlesEdit = React.lazy(() => import("../../../views/loyalty/reward-bundles-edit"));
const SettingsScopeUsageLimitsList = React.lazy(() =>
  import("../../../views/general-settings/scope-usage-limits-list")
);
const SettingsScopeUsageLimitsEdit = React.lazy(() =>
  import("../../../views/general-settings/scope-usage-limits-edit")
);
const SettingsScopeUsageInstancesList = React.lazy(() =>
  import("../../../views/general-settings/scope-usage-instances-list")
);
const SettingsScopeUsageAssociationsList = React.lazy(() =>
  import("../../../views/general-settings/scope-usage-associations-list")
);

const PlayerPartialAccounts = React.lazy(() =>
  import("../../../views-core-admin/player-partial-accounts/player-partial-accounts")
);

const BulkToolsDeduction = React.lazy(() => import("../../../views-core-admin/bulk-tools/deduction"));
const BulkToolsGrantBonus = React.lazy(() => import("../../../views-core-admin/bulk-tools/grant-bonus"));
const BulkToolsGrantPlayerTag = React.lazy(() => import("../../../views-core-admin/bulk-tools/grant-player-tag"));
const BulkToolsUpdateAffiliateInfo = React.lazy(() =>
  import("../../../views-core-admin/bulk-tools/update-affiliate-info")
);
const BulkToolsGrantPrizes = React.lazy(() => import("../../../views-core-admin/bulk-tools/grant-prizes"));

const RiskAndPerformanceAlerts = React.lazy(() =>
  import("../../../views-core-admin/risk-and-performance-alerts/alerts")
);
const DueDiligenceQueue = React.lazy(() =>
  import("../../../views-core-admin/risk-and-performance-due-diligence/due-diligence-queue")
);
const DocumentsVerificationQueue = React.lazy(() =>
  import("../../../views-core-admin/risk-and-performance-due-diligence/documents-verification-queue")
);
const ClearClosedAccounts = React.lazy(() =>
  import("../../../views-core-admin/risk-and-performance-clear-closed-accounts/clear-closed-accounts")
);

const CnpWhitelistList = React.lazy(() =>
  import("../../../views-core-admin/register-manage-social-number/register-manage-social-number")
);

const VivaWithdrawal = React.lazy(() => import("../../../views/Viva/Withdrawal"));
const VivaWithdrawalLog = React.lazy(() => import("../../../views/Viva/WithdrawalLog"));
const VivaDeposits = React.lazy(() => import("../../../views/Viva/Deposits"));

const CoreWithdrawal = React.lazy(() => import("../../../views-core-admin/withdrawals/withdrawals-search"));

const CashierSessionsList = React.lazy(() => import("../../../views-core-admin/cashier/cashier-sessions-list"));
const CashierSessionDetails = React.lazy(() => import("../../../views-core-admin/cashier/cashier-session-details"));
const CashierTransactionsList = React.lazy(() => import("../../../views-core-admin/cashier/cashier-transactions-list"));
const CashierTransactionsDetails = React.lazy(() =>
  import("../../../views-core-admin/cashier/cashier-transaction-details")
);
const CashierReportsList = React.lazy(() => import("../../../views-core-admin/cashier/cashier-reports-list"));
const CashierReportsDetails = React.lazy(() => import("../../../views-core-admin/cashier/cashier-report-details"));

const AdminNotificationsList = React.lazy(() =>
  import("../../../views-core-admin/admin-notifications/notifications-list")
);
const AdminNotificationsSubscriptionsList = React.lazy(() =>
  import("../../../views-core-admin/admin-notifications/notifications-subscriptions-list")
);

const AdminAutomationTasks = React.lazy(() => import("../../../views-core-admin/admin-automation/admin-tasks"));
const AdminAutomationCurrentTasks = React.lazy(() =>
  import("../../../views-core-admin/admin-automation/admin-current-tasks")
);
const AdminAutomationCompletedTasks = React.lazy(() =>
  import("../../../views-core-admin/admin-automation/admin-completed-tasks")
);
const AdminAutomationTaskDetails = React.lazy(() =>
  import("../../../views-core-admin/admin-automation/admin-task-details")
);

const AdminAutomationTranslationsLanguagesList = React.lazy(() =>
  import("../../../views-core-admin/multi-languages/languages-list")
);
const AdminAutomationTranslationsProjectsList = React.lazy(() =>
  import("../../../views-core-admin/multi-languages/projects-list")
);
const AdminAutomationTranslationsTextsList = React.lazy(() =>
  import("../../../views-core-admin/multi-languages/texts-list")
);
const AdminAutomationTranslationsList = React.lazy(() =>
  import("../../../views-core-admin/multi-languages/translations")
);

const TemplatizationPages = React.lazy(() => import("../../../views-templatization/pages/pages"));
const TemplatizationPageEdit = React.lazy(() => import("../../../views-templatization/pages/page-edit"));
const TemplatizationPageLayouts = React.lazy(() => import("../../../views-templatization/page-layouts/page-layouts"));
const TemplatizationPageLayoutEdit = React.lazy(() =>
  import("../../../views-templatization/page-layouts/page-layout-edit")
);

const TemplatizationElements = React.lazy(() => import("../../../views-templatization/elements/elements"));
const TemplatizationVisualElementEdit = React.lazy(() =>
  import("../../../views-templatization/elements/edit-visual-element")
);

const TemplatizationComponents = React.lazy(() => import("../../../views-templatization/components/components"));
const TemplatizationComponentEdit = React.lazy(() => import("../../../views-templatization/components/edit-component"));
const TemplatizationVisualComponentEdit = React.lazy(() =>
  import("../../../views-templatization/components/edit-visual-component")
);
const TemplatizationCSSVariables = React.lazy(() =>
  import("../../../views-templatization/css-variables/css-variables")
);
const TemplatizationCSSVariableEdit = React.lazy(() =>
  import("../../../views-templatization/css-variables/edit-css-variable")
);
const TemplatizationCommonCSS = React.lazy(() => import("../../../views-templatization/common-css/common-css"));
const TemplatizationDesignCSS = React.lazy(() => import("../../../views-templatization/design-css/design-css"));

const TemplatizationSetsComponentsDefaults = React.lazy(() =>
  import("../../../views-templatization/sets-components-defaults/sets-components-defaults")
);
const TemplatizationSetComponentsDefaultsEdit = React.lazy(() =>
  import("../../../views-templatization/sets-components-defaults/edit-set-components-defaults")
);
const TemplatizationSetsComponentsDataDefaults = React.lazy(() =>
  import("../../../views-templatization/sets-components-data-defaults/sets-components-data-defaults")
);
const TemplatizationSetComponentsDataDefaultsEdit = React.lazy(() =>
  import("../../../views-templatization/sets-components-data-defaults/edit-set-components-data-defaults")
);
const TemplatizationSetsCSSVariables = React.lazy(() =>
  import("../../../views-templatization/sets-css-variables/sets-css-variables")
);
const TemplatizationSetCSSVariablesEdit = React.lazy(() =>
  import("../../../views-templatization/sets-css-variables/edit-set-css-variables")
);

const TemplatizationSetsPageMetaDefaults = React.lazy(() =>
  import("../../../views-templatization/sets-page-meta-defaults/sets-page-meta-defaults")
);
const TemplatizationSetPageMetaDefaultsEdit = React.lazy(() =>
  import("../../../views-templatization/sets-page-meta-defaults/edit-set-page-meta-defaults")
);

const ImportTokens = React.lazy(() => import("../../../views-templatization/sets-css-variables/import-tokens"));
const TemplatizationSetsPages = React.lazy(() => import("../../../views-templatization/sets-pages/sets-pages"));
const TemplatizationSetPagesEdit = React.lazy(() => import("../../../views-templatization/sets-pages/edit-set-pages"));
const TemplatizationSetsFonts = React.lazy(() => import("../../../views-templatization/sets-fonts/sets-fonts"));
const TemplatizationSetFontsEdit = React.lazy(() => import("../../../views-templatization/sets-fonts/edit-set-fonts"));
const TemplatizationSiteTemplates = React.lazy(() =>
  import("../../../views-templatization/site-templates/site-templates")
);
const TemplatizationSiteTemplateEdit = React.lazy(() =>
  import("../../../views-templatization/site-templates/edit-site-templates")
);
const TemplatizationBrandsSites = React.lazy(() => import("../../../views-templatization/brands-sites/brands-sites"));
const TemplatizationBrandSitesEdit = React.lazy(() =>
  import("../../../views-templatization/brands-sites/edit-brand-sites")
);
const TemplatizationComponentsData = React.lazy(() =>
  import("../../../views-templatization/components-data/components-data")
);
const TemplatizationComponentDataEdit = React.lazy(() =>
  import("../../../views-templatization/components-data/edit-component-data")
);
const TemplatizationVisualComponentDataEdit = React.lazy(() =>
  import("../../../views-templatization/components-data/edit-visual-component-data")
);

const TemplatizationEditorThemeStyles = React.lazy(() =>
  import("../../../views-templatization/editor-styles-presets/editor-styles-presets")
);
const TemplatizationEditorThemeStyleEdit = React.lazy(() =>
  import("../../../views-templatization/editor-styles-presets/edit-editor-styles-preset")
);
const TemplatizationCssBreakPoints = React.lazy(() =>
  import("../../../views-templatization/css-break-points/css-break-points")
);

const TemplatizationDesignVariants = React.lazy(() =>
  import("../../../views-templatization/design-variants/design-variants")
);
const TemplatizationDesignVariantEdit = React.lazy(() =>
  import("../../../views-templatization/design-variants/edit-design-variant")
);
const TemplatizationDesignVariantVisualEdit = React.lazy(() =>
  import("../../../views-templatization/design-variants/edit-visual-design-variant")
);
const TemplatizationDesignSeeds = React.lazy(() => import("../../../views-templatization/design-seeds/design-seeds"));
const TemplatizationDesignSeedEdit = React.lazy(() =>
  import("../../../views-templatization/design-seeds/edit-design-seed")
);

const TemplatizationRenderTest = React.lazy(() => import("../../../views-templatization/render-test/render-test"));
const TemplatizationEditorTest = React.lazy(() => import("../../../views-templatization/editor-test/index"));

const TemplatizationPopups = React.lazy(() => import("../../../views-templatization/popups/popups"));
const TemplatizationPopupEdit = React.lazy(() => import("../../../views-templatization/popups/popup-edit"));
const TemplatizationSetsPopups = React.lazy(() => import("../../../views-templatization/sets-popups/sets-popups"));
const TemplatizationSetPopupsEdit = React.lazy(() =>
  import("../../../views-templatization/sets-popups/edit-set-popups")
);

const WhiteLabelCreateBrand = React.lazy(() =>
  import("../../../views-templatization/white-label/white-label-create-brand")
);
const WhiteLabelDomainAdministration = React.lazy(() =>
  import("../../../views-templatization/white-label-domain-administration/domain-administration")
);
const WhiteLabelContentDeployment = React.lazy(() =>
  import("../../../views-templatization/white-label-content-deployment/content-deployment")
);

const WhiteLabelSessions = React.lazy(() => import("../../../views-templatization/wizard-sessions/sessions"));

const ContentManagementDefinitionsList = React.lazy(() =>
  import("../../../views-templatization/content-management/definitions-list")
);
const ContentManagementDefinitionEdit = React.lazy(() =>
  import("../../../views-templatization/content-management/definitions-edit")
);
const ContentManagementTemplatesList = React.lazy(() =>
  import("../../../views-templatization/content-management/templates-list")
);
const ContentManagementTemplatesEdit = React.lazy(() =>
  import("../../../views-templatization/content-management/templates-edit")
);
const ContentManagementOverrides = React.lazy(() =>
  import("../../../views-templatization/content-management/overrides-list")
);
const ContentManagementDeploymentsList = React.lazy(() =>
  import("../../../views-templatization/content-management/deployments-list")
);
const ContentManagementDeploymentsEdit = React.lazy(() =>
  import("../../../views-templatization/content-management/deployments-edit")
);

const DataElementTemplate = React.lazy(() => import("../../../views-templatization/data/data-element-template"));
const DataElementTemplateList = React.lazy(() =>
  import("../../../views-templatization/data/data-element-template-list")
);
const DataElement = React.lazy(() => import("../../../views-templatization/data/data-element"));
const DataElementList = React.lazy(() => import("../../../views-templatization/data/data-element-list"));
const DataSource = React.lazy(() => import("../../../views-templatization/data/data-source"));
const DataSourceList = React.lazy(() => import("../../../views-templatization/data/data-source-list"));
const DataSourcesReducersList = React.lazy(() =>
  import("../../../views-templatization/data/data-source-reducers-list")
);
const DataSourcesReducer = React.lazy(() => import("../../../views-templatization/data/data-source-reducer"));
const DataSourcePartialList = React.lazy(() => import("../../../views-templatization/data/data-source-partial-list"));
const DataSourcePartial = React.lazy(() => import("../../../views-templatization/data/data-source-partial"));

const PrizeTemplatizationList = React.lazy(() =>
  import("../../../views-templatization/prize-templates/prize-templates")
);
const PrizeTemplatizationEdit = React.lazy(() =>
  import("../../../views-templatization/prize-templates/edit-prize-template")
);
const PrizeRewardInstances = React.lazy(() =>
  import("../../../views-templatization/prize-templates/prize-reward-instances")
);
const PrizeRewardInstanceEdit = React.lazy(() =>
  import("../../../views-templatization/prize-templates/edit-prize-reward-instance")
);

// prize templates
const PrizeTemplatesList = React.lazy(() => import("../../../views/prizes/prize-template-list"));
const PrizeTemplateEdit = React.lazy(() => import("../../../views/prizes/prize-template-edit-new"));

// wip - jackpot section

const JackpotDefinitions = React.lazy(() => import("../../../views/jackpot/definitions/jackpot-definitions"));
const JackpotDefinitionsList = React.lazy(() =>
  import("../../../views/jackpot/definitions/components/jackpot-definitions-list")
);
const JackpotSourcesList = React.lazy(() => import("../../../views/jackpot/sources/jackpot-sources-list"));
const JackpotSourcesDetails = React.lazy(() => import("../../../views/jackpot/sources/jackpot-sources-details"));
const JackpotSourcesTokensList = React.lazy(() => import("../../../views/jackpot/sources/jackpot-source-tokens-list"));
const JackpotSourcesTokensDetails = React.lazy(() =>
  import("../../../views/jackpot/sources/jackpot-source-tokens-details")
);
const JackpotSourcesSecurityTokensList = React.lazy(() =>
  import("../../../views/jackpot/sources/jackpot-source-security-tokens-list")
);
const JackpotSourcesSecurityTokensDetails = React.lazy(() =>
  import("../../../views/jackpot/sources/jackpot-source-security-tokens-details")
);
const JackpotWinHistory = React.lazy(() => import("../../../views/jackpot/history/jackpot-win-history"));
const JackpotWinHistoryDetails = React.lazy(() => import("../../../views/jackpot/history/jackpot-win-history-details"));

const MyProfileList = React.lazy(() => import("../../../views/jackpot/my-profile/my-profile-list"));
const JackpotGroupsList = React.lazy(() => import("../../../views/jackpot/groups/jackpot-groups-list"));
const JackpotGroupEdit = React.lazy(() => import("../../../views/jackpot/groups/jackpot-groups-edit"));
// const JackpotSetupDetails = React.lazy(() => import("../../../views/jackpot/setup/jackpot-setup-details"));
const JackpotSourceJackpotsList = React.lazy(() =>
  import("../../../views/jackpot-source/jackpot-source-jackpots-list")
);
const JackpotSourceJackpotDetails = React.lazy(() =>
  import("../../../views/jackpot-source/jackpot-source-jackpot-details")
);

const PostbackTrackingSearch = React.lazy(() =>
  import("../../../views-core-admin/affiliates-postback-tracking/postback-tracking-search")
);
const PostbackTrackingEdit = React.lazy(() =>
  import("../../../views-core-admin/affiliates-postback-tracking/postback-tracking-edit")
);
const TrafficNetworkSearch = React.lazy(() =>
  import("../../../views-core-admin/affiliates-postback-tracking/traffic-network-search")
);
const TrafficNetworkEdit = React.lazy(() =>
  import("../../../views-core-admin/affiliates-postback-tracking/traffic-network-edit")
);

const CalendarCampaignsList = React.lazy(() => import("../../../views/calendar/CampaignsList"));
const CalendarCampaignsEdit = React.lazy(() => import("../../../views/calendar/CampaignsEdit"));
const CalendarPrizesList = React.lazy(() => import("../../../views/calendar/PrizesList"));
const CalendarPrizesEdit = React.lazy(() => import("../../../views/calendar/PrizesEdit"));
const CalendarPlayersCampaignDataList = React.lazy(() => import("../../../views/calendar/PlayersCampaignDataList"));
const CalendarPlayersPrizeDataList = React.lazy(() => import("../../../views/calendar/PlayersPrizeDataList"));
const CalendarPlayersOperationsList = React.lazy(() => import("../../../views/calendar/PlayersOperationsList"));

const AirdropCampaignList = React.lazy(() => import("../../../views/airdrop/AirdropCampaignList"));
const AirdropCampaignEdit = React.lazy(() => import("../../../views/airdrop/AirdropCampaignEdit"));
const AirdropItemsList = React.lazy(() => import("../../../views/airdrop/AirdropItemsList"));
const AirdropItemsEdit = React.lazy(() => import("../../../views/airdrop/AirdropItemsEdit"));
const AirdropCampaignItemDataList = React.lazy(() => import("../../../views/airdrop/AirdropCampaignItemDataList"));
const AirdropPlayersItemDataList = React.lazy(() => import("../../../views/airdrop/AirdropPlayersItemDataList"));
const AirdropPlayersOperationsList = React.lazy(() => import("../../../views/airdrop/AirdropPlayersOperationsList"));

const MomentumSettings = React.lazy(() => import("../../../views/momentum/MomentumSettings"));
const MomentumBinds = React.lazy(() => import("../../../views/momentum/MomentumBinds"));
const MomentumAwardsList = React.lazy(() => import("../../../views/momentum/MomentumAwardsList"));
const MomentumAwardsDetails = React.lazy(() => import("../../../views/momentum/MomentumAwardsDetails"));

const SocialPlayerNicknames = React.lazy(() => import("../../../views/social/nicknames"));
const SocialAvatarsDefinitions = React.lazy(() => import("../../../views/social/avatars-definitions"));
const SocialPlayerAvatars = React.lazy(() => import("../../../views/social/avatars"));
const SocialManagementList = React.lazy(() => import("../../../views/social/social_management_list"));
const SocialManagementEdit = React.lazy(() => import("../../../views/social/edit_social_management"));

const PagesCustomizations = React.lazy(() => import("../../../views-templatization/customizations/customizations"));
const PagesCustomizationEdit = React.lazy(() =>
  import("../../../views-templatization/customizations/customization-edit")
);
const CustomizationDataSource = React.lazy(() =>
  import("../../../views-templatization/customizations/customization-data-source")
);

const MobileAppViews = React.lazy(() => import("../../../views/mobile-app/views"));
const MobileAppViewEdit = React.lazy(() => import("../../../views/mobile-app/view-edit"));

const BetsMarketSorter = React.lazy(() => import("../../../views/BetsMarketsSorter"));

const SmsValidationWinner = React.lazy(() => import("../../../views/sms-codes/winner-sms-validation"));
const SmsValidationWL = React.lazy(() => import("../../../views/sms-codes/wl-sms-validation"));
const SMSAuthCode = React.lazy(() => import("../../../views/sms-codes/sms-auth-code"));
const BridgerPaymentMethods = React.lazy(() =>
  import("../../../views-core-admin/bridger-payment-methods/bridger-payment-methods")
);
const SyncProfiles = React.lazy(() => import("../../../views/sync/profiles"));
const SyncProfileEdit = React.lazy(() => import("../../../views/sync/profile-edit"));
const Tools = React.lazy(() => import("../../../views/tools/tools"));
const ConsulEditor = React.lazy(() => import("../../../views/consul/consul-editor"));

const CustomerSearchSettings = React.lazy(() =>
  import("../../../views/customer-search-settings/customer-search-settings")
);

const rootPath = window.ini.REACT_APP_ROOT_PATH;

// ** Merge Routes
const Routes = [
  {
    exact: true,
    name: "Mobile App Views",
    path: `${rootPath}/mobile-app/views`,
    component: MobileAppViews,
  },
  {
    exact: true,
    canGoBack: true,
    name: "Mobile App View Edit",
    path: `${rootPath}/mobile-app/views/:tenantId/:viewId`,
    component: MobileAppViewEdit,
  },
  {
    exact: true,
    name: "Player Nicknames",
    path: `${rootPath}/social/players`,
    component: SocialPlayerNicknames,
  },
  {
    exact: true,
    name: "Avatars Defintions",
    path: `${rootPath}/social/definitions-avatars/:tenantId`,
    component: SocialAvatarsDefinitions,
  },
  {
    exact: true,
    name: "Avatars",
    path: `${rootPath}/social/avatars`,
    component: SocialPlayerAvatars,
  },

  {
    exact: true,
    name: "Social Management",
    path: `${rootPath}/social/management`,
    component: SocialManagementList,
  },
  {
    exact: true,
    name: "Edit Social Flags",
    path: `${rootPath}/social/management-edit/:tenantId/:id`,
    component: SocialManagementEdit,
  },

  {
    exact: true,
    name: "Jackpot List",
    path: `${rootPath}/jackpot/definitions`,
    component: JackpotDefinitionsList,
  },
  {
    exact: true,
    canGoBack: true,
    name: "Jackpot Details",
    path: `${rootPath}/jackpot/definitions/:id`,
    component: JackpotDefinitions,
  },
  {
    exact: true,
    name: "Sources List",
    path: `${rootPath}/jackpot/sources/list`,
    component: JackpotSourcesList,
  },
  {
    exact: true,
    name: "Sources Details",
    canGoBack: true,
    path: `${rootPath}/jackpot/sources/details/:id`,
    component: JackpotSourcesDetails,
  },
  {
    exact: true,
    name: "Tokens List",
    path: `${rootPath}/jackpot/sources/tokens/list`,
    component: JackpotSourcesTokensList,
  },
  {
    exact: true,
    name: "Tokens Details",
    canGoBack: true,
    path: `${rootPath}/jackpot/sources/tokens/details/:id`,
    component: JackpotSourcesTokensDetails,
  },
  {
    exact: true,
    name: "Security Tokens List",
    path: `${rootPath}/jackpot/sources/security-tokens/list`,
    component: JackpotSourcesSecurityTokensList,
  },
  {
    exact: true,
    name: "Security Tokens Details",
    canGoBack: true,
    path: `${rootPath}/jackpot/sources/security-tokens/details/:id`,
    component: JackpotSourcesSecurityTokensDetails,
  },
  {
    exact: true,
    name: "Win History",
    path: `${rootPath}/jackpot/win-history/list`,
    component: JackpotWinHistory,
  },
  {
    exact: true,
    name: "Win History Details",
    canGoBack: true,
    path: `${rootPath}/jackpot/win-history/:id`,
    component: JackpotWinHistoryDetails,
  },
  {
    exact: true,
    name: "My Profile",
    path: `${rootPath}/jackpot/my-profile/list`,
    component: MyProfileList,
  },
  // {
  //   exact: true,
  //   name: "Jackpot Setup",
  //   canGoBack: true,
  //   path: `${rootPath}/jackpot/jackpot/setup/`,
  //   component: JackpotSetupDetails,
  // },

  {
    exact: true,
    name: "Jackpot Groups List",
    canGoBack: false,
    path: `${rootPath}/jackpot/groups/`,
    component: JackpotGroupsList,
  },
  {
    exact: true,
    name: "Jackpot Groups Edit",
    canGoBack: true,
    path: `${rootPath}/jackpot/group/:id`,
    component: JackpotGroupEdit,
  },

  {
    exact: true,
    name: "Jackpots List",
    canGoBack: true,
    path: `${rootPath}/jackpot-source/jackpot/list`,
    component: JackpotSourceJackpotsList,
  },
  {
    exact: true,
    name: "Jackpot Source Details",
    canGoBack: true,
    path: `${rootPath}/jackpot-source/jackpot/details/:id`,
    component: JackpotSourceJackpotDetails,
  },

  {
    exact: true,
    name: "Render Test",
    path: `${rootPath}/templatization/render-test`,
    component: TemplatizationRenderTest,
  },
  {
    exact: true,
    name: "Editor Test",
    path: `${rootPath}/templatization/editor-test`,
    layout: "BlankLayout",
    component: TemplatizationEditorTest,
  },
  {
    exact: true,
    name: "Elements",
    path: `${rootPath}/templatization/elements`,
    component: TemplatizationElements,
  },
  {
    exact: true,
    name: "Element Edit",
    path: `${rootPath}/templatization/edit-visual-element/:tenantId/:id`,
    layout: "BlankLayout",
    component: TemplatizationVisualElementEdit,
  },
  {
    exact: true,
    name: "Customizations",
    path: `${rootPath}/templatization/customizations`,
    component: PagesCustomizations,
  },
  {
    exact: true,
    name: "Component Edit",
    path: `${rootPath}/templatization/edit-customization/:tenantId/:siteId/:pageId`,
    layout: "BlankLayout",
    component: PagesCustomizationEdit,
  },
  {
    exact: true,
    name: "Customization Data Source",
    path: `${rootPath}/templatization/data-source-customization`,
    layout: "BlankLayout",
    component: CustomizationDataSource,
  },
  {
    exact: true,
    name: "Components",
    path: `${rootPath}/templatization/components`,
    component: TemplatizationComponents,
  },
  {
    exact: true,
    name: "Component Edit",
    path: `${rootPath}/templatization/edit-component/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationComponentEdit,
  },
  {
    exact: true,
    name: "Component Edit",
    path: `${rootPath}/templatization/edit-visual-component/:tenantId/:id`,
    layout: "BlankLayout",
    component: TemplatizationVisualComponentEdit,
  },
  {
    exact: true,
    name: "CSS Variables",
    path: `${rootPath}/templatization/css-variables`,
    component: TemplatizationCSSVariables,
  },
  {
    exact: true,
    name: "Edit CSS Variable",
    path: `${rootPath}/templatization/edit-css-variable/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationCSSVariableEdit,
  },
  {
    showExpandButton: true,
    exact: true,
    name: "Common SCSS",
    path: `${rootPath}/templatization/common-css`,
    component: TemplatizationCommonCSS,
  },
  {
    showExpandButton: true,
    exact: true,
    name: "Design SCSS",
    path: `${rootPath}/templatization/design-css`,
    component: TemplatizationDesignCSS,
  },
  {
    exact: true,
    name: "Components Overrides",
    path: `${rootPath}/templatization/sets-components-defaults`,
    component: TemplatizationSetsComponentsDefaults,
  },
  {
    exact: true,
    name: "Edit Components Overrides",
    path: `${rootPath}/templatization/edit-set-components-defaults/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetComponentsDefaultsEdit,
  },
  {
    exact: true,
    name: "Components Data Overrides",
    path: `${rootPath}/templatization/sets-components-data-defaults`,
    component: TemplatizationSetsComponentsDataDefaults,
  },
  {
    exact: true,
    name: "Edit Components Data Overrides",
    path: `${rootPath}/templatization/edit-set-components-data-defaults/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetComponentsDataDefaultsEdit,
  },

  {
    exact: true,
    name: "Page Meta Data Overrides",
    path: `${rootPath}/templatization/sets-page-meta-defaults`,
    component: TemplatizationSetsPageMetaDefaults,
  },
  {
    exact: true,
    name: "Edit Page Meta Data Overrides",
    path: `${rootPath}/templatization/edit-set-page-meta-defaults/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetPageMetaDefaultsEdit,
  },
  {
    exact: true,
    name: "Components Data",
    path: `${rootPath}/templatization/data-components`,
    component: TemplatizationComponentsData,
  },
  {
    exact: true,
    name: "Edit Component Data",
    path: `${rootPath}/templatization/edit-data-component/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationComponentDataEdit,
  },
  {
    exact: true,
    name: "Edit Component Data",
    path: `${rootPath}/templatization/edit-visual-data-component/:tenantId/:componentId/:id`,
    canGoBack: true,
    layout: "BlankLayout",
    component: TemplatizationVisualComponentDataEdit,
  },
  {
    exact: true,
    name: "CSS Sets Colors",
    path: `${rootPath}/templatization/sets-css-colors/:category`,
    component: TemplatizationSetsCSSVariables,
  },
  {
    exact: true,
    name: "Edit Colors Set",
    path: `${rootPath}/templatization/edit-set-css-colors/:category/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetCSSVariablesEdit,
  },
  {
    exact: true,
    name: "CSS Sets Spacing",
    path: `${rootPath}/templatization/sets-css-spacing/:category`,
    component: TemplatizationSetsCSSVariables,
  },
  {
    exact: true,
    name: "Edit Spacing Set",
    path: `${rootPath}/templatization/edit-set-css-spacing/:category/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetCSSVariablesEdit,
  },
  {
    exact: true,
    name: "CSS Sets Component Variables",
    path: `${rootPath}/templatization/sets-css-component-variables/:category`,
    component: TemplatizationSetsCSSVariables,
  },
  {
    exact: true,
    name: "Edit Component Variables Set",
    path: `${rootPath}/templatization/edit-set-css-component-variables/:category/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetCSSVariablesEdit,
  },
  {
    exact: true,
    name: "CSS Sets Style Border",
    path: `${rootPath}/templatization/sets-css-style-border/:category`,
    component: TemplatizationSetsCSSVariables,
  },
  {
    exact: true,
    name: "Edit Style Border Set",
    path: `${rootPath}/templatization/edit-set-css-style-border/:category/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetCSSVariablesEdit,
  },
  {
    exact: true,
    name: "CSS Sets Style Border Radius",
    path: `${rootPath}/templatization/sets-css-style-border-radius/:category`,
    component: TemplatizationSetsCSSVariables,
  },
  {
    exact: true,
    name: "Edit Style Border Radius Set",
    path: `${rootPath}/templatization/edit-set-css-style-border-radius/:category/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetCSSVariablesEdit,
  },
  {
    exact: true,
    name: "CSS Sets Style Shadow",
    path: `${rootPath}/templatization/sets-css-style-shadow/:category`,
    component: TemplatizationSetsCSSVariables,
  },
  {
    exact: true,
    name: "Edit Style Shadow Set",
    path: `${rootPath}/templatization/edit-set-css-style-shadow/:category/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetCSSVariablesEdit,
  },
  {
    exact: true,
    name: "CSS Sets Fonts",
    path: `${rootPath}/templatization/sets-css-fonts/:category`,
    component: TemplatizationSetsCSSVariables,
  },
  {
    exact: true,
    name: "Edit Font Set",
    path: `${rootPath}/templatization/edit-set-css-fonts/:category/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetCSSVariablesEdit,
  },
  {
    exact: true,
    name: "Import Figma Studio Tokens",
    path: `${rootPath}/templatization/import-tokens`,
    component: ImportTokens,
  },

  {
    exact: true,
    name: "Sets Popups",
    path: `${rootPath}/templatization/sets-popups`,
    component: TemplatizationSetsPopups,
  },
  {
    exact: true,
    name: "Edit Set Popups",
    path: `${rootPath}/templatization/edit-set-popups/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetPopupsEdit,
  },

  {
    exact: true,
    name: "Sets Pages",
    path: `${rootPath}/templatization/sets-pages`,
    component: TemplatizationSetsPages,
  },
  {
    exact: true,
    name: "Edit Set Pages",
    path: `${rootPath}/templatization/edit-set-pages/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetPagesEdit,
  },
  {
    exact: true,
    name: "Editor Styles Presets",
    path: `${rootPath}/templatization/editor-styles-presets`,
    component: TemplatizationEditorThemeStyles,
  },
  {
    exact: true,
    name: "Edit Editor Styles Preset",
    path: `${rootPath}/templatization/edit-editor-styles-preset/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationEditorThemeStyleEdit,
  },
  {
    exact: true,
    name: "Break Points",
    path: `${rootPath}/templatization/css-break-points`,
    component: TemplatizationCssBreakPoints,
  },
  {
    exact: true,
    name: "Sets Fonts",
    path: `${rootPath}/templatization/sets-fonts`,
    component: TemplatizationSetsFonts,
  },
  {
    exact: true,
    name: "Edit Set Fonts",
    path: `${rootPath}/templatization/edit-set-fonts/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSetFontsEdit,
  },
  {
    exact: true,
    name: "Sites Templates",
    path: `${rootPath}/templatization/sites-templates`,
    component: TemplatizationSiteTemplates,
  },
  {
    exact: true,
    name: "Edit Site Template",
    path: `${rootPath}/templatization/edit-site-template/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationSiteTemplateEdit,
  },
  {
    exact: true,
    name: "Design Seeds",
    path: `${rootPath}/templatization/design-seeds`,
    component: TemplatizationDesignSeeds,
  },
  {
    exact: true,
    name: "Edit Design Seed",
    path: `${rootPath}/templatization/edit-design-seed/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationDesignSeedEdit,
  },
  {
    exact: true,
    name: "Design Variants",
    path: `${rootPath}/templatization/design-variants`,
    component: TemplatizationDesignVariants,
  },
  {
    exact: true,
    name: "Edit Design Variant",
    path: `${rootPath}/templatization/edit-design-variant/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationDesignVariantEdit,
  },
  {
    exact: true,
    name: "Edit Design Variant",
    path: `${rootPath}/templatization/edit-visual-design-variant/:tenantId/:id`,
    canGoBack: true,
    layout: "BlankLayout",
    component: TemplatizationDesignVariantVisualEdit,
  },
  {
    exact: true,
    name: "Brands Sites",
    path: `${rootPath}/templatization/brands-sites`,
    component: TemplatizationBrandsSites,
  },
  {
    exact: true,
    name: "Edit Brand Site",
    path: `${rootPath}/templatization/edit-brand-site/:tenantId/:id`,
    canGoBack: true,
    component: TemplatizationBrandSitesEdit,
  },

  {
    exact: true,
    name: "Popups",
    path: `${rootPath}/templatization/popups`,
    component: TemplatizationPopups,
  },
  {
    exact: true,
    name: "Popup Edit",
    path: `${rootPath}/templatization/edit-popup/:tenantId/:pageId`,
    canGoBack: true,
    layout: "BlankLayout",
    component: TemplatizationPopupEdit,
  },

  {
    exact: true,
    name: "Pages",
    path: `${rootPath}/templatization/pages`,
    component: TemplatizationPages,
  },
  {
    exact: true,
    name: "Page Edit",
    path: `${rootPath}/templatization/edit-page/:tenantId/:pageId`,
    canGoBack: true,
    layout: "BlankLayout",
    component: TemplatizationPageEdit,
  },
  {
    exact: true,
    name: "Page Layouts",
    path: `${rootPath}/templatization/layout-pages`,
    component: TemplatizationPageLayouts,
  },
  {
    exact: true,
    name: "Page Layout Edit",
    path: `${rootPath}/templatization/edit-layout-page/:tenantId/:pageLayoutId`,
    canGoBack: true,
    layout: "BlankLayout",
    component: TemplatizationPageLayoutEdit,
  },

  {
    exact: true,
    name: "Home",
    path: `${rootPath}/`,
    component: Home,
  },
  {
    exact: true,
    name: "Admin Automation Tasks",
    path: `${rootPath}/admin/automation/tasks`,
    component: AdminAutomationTasks,
  },
  {
    exact: true,
    name: "Admin Current Automation Tasks",
    path: `${rootPath}/admin/automation/tasks/current`,
    component: AdminAutomationCurrentTasks,
  },
  {
    exact: true,
    name: "Admin Completed Automation Tasks",
    path: `${rootPath}/admin/automation/tasks/completed`,
    component: AdminAutomationCompletedTasks,
  },
  {
    exact: true,
    name: "Admin Automation Task Details",
    path: `${rootPath}/admin/automation/task/:taskId`,
    canGoBack: true,
    component: AdminAutomationTaskDetails,
  },
  {
    exact: true,
    name: "Translations Languages List",
    path: `${rootPath}/admin/automation/languages`,
    canGoBack: true,
    component: AdminAutomationTranslationsLanguagesList,
  },
  {
    exact: true,
    name: "Translations Projects List",
    path: `${rootPath}/admin/automation/projects`,
    canGoBack: true,
    component: AdminAutomationTranslationsProjectsList,
  },
  {
    exact: true,
    name: "Translations Texts List",
    path: `${rootPath}/admin/automation/texts`,
    canGoBack: true,
    component: AdminAutomationTranslationsTextsList,
  },
  {
    exact: true,
    name: "Translations List",
    path: `${rootPath}/admin/automation/translations`,
    canGoBack: true,
    component: AdminAutomationTranslationsList,
  },
  {
    exact: true,
    name: "Admin Notifications List",
    path: `${rootPath}/admin/notifications/list`,
    component: AdminNotificationsList,
  },
  {
    exact: true,
    name: "Admin Subscriptions List",
    path: `${rootPath}/admin/notifications/subscriptions`,
    component: AdminNotificationsSubscriptionsList,
  },
  {
    exact: true,
    name: "Cashier Sessions" + " (WIP)",
    path: `${rootPath}/cashier/sessions/:clientId/`,
    component: CashierSessionsList,
  },
  {
    exact: true,
    name: "Cashier Session" + " (WIP)",
    path: `${rootPath}/cashier/session/:sessionId`,
    canGoBack: true,
    component: CashierSessionDetails,
  },
  {
    exact: true,
    name: "Cashier Transactions" + " (WIP)",
    path: `${rootPath}/cashier/transactions/:clientId/`,
    component: CashierTransactionsList,
  },
  {
    exact: true,
    name: "Cashier Transaction" + " (WIP)",
    path: `${rootPath}/cashier/transaction/:transactionId`,
    canGoBack: true,
    component: CashierTransactionsDetails,
  },
  {
    exact: true,
    name: "Cashier Reports" + " (WIP)",
    path: `${rootPath}/cashier/reports/:clientId/`,
    component: CashierReportsList,
  },
  {
    exact: true,
    name: "Cashier Report" + " (WIP)",
    path: `${rootPath}/cashier/report/:transactionId`,
    canGoBack: true,
    component: CashierReportsDetails,
  },

  {
    exact: true,
    name: "Withdrawal",
    path: `${rootPath}/viva/withdrawal`,
    component: VivaWithdrawal,
  },
  {
    exact: true,
    name: "Withdrawal Log",
    path: `${rootPath}/viva/logs-withdrawal`,
    component: VivaWithdrawalLog,
  },
  {
    exact: true,
    name: "Deposits",
    path: `${rootPath}/viva/deposits`,
    component: VivaDeposits,
  },
  {
    exact: true,
    name: "Home",
    path: `${rootPath}/home`,
    component: Home,
  },
  {
    exact: true,
    name: "Manage Social Number",
    path: `${rootPath}/manage-social-number`,
    component: CnpWhitelistList,
  },

  {
    exact: true,
    name: "Withdrawals",
    path: `${rootPath}/payments/withdrawals`,
    component: CoreWithdrawal,
  },

  {
    exact: true,
    name: "Currency List",
    path: `${rootPath}/general-settings/currency`,
    component: SettingsCurrencyList,
  },
  {
    exact: true,
    name: "Currency Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/currency/:id`,
    component: SettingsCurrencyEdit,
  },
  {
    exact: true,
    name: "Country List",
    path: `${rootPath}/general-settings/country`,
    component: SettingsCountryList,
  },
  {
    exact: true,
    name: "Country Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/country/:id`,
    component: SettingsCountryEdit,
  },
  {
    exact: true,
    name: "Geo Region List",
    path: `${rootPath}/general-settings/geo-region`,
    component: SettingsGeoRegionList,
  },
  {
    exact: true,
    name: "Geo Region Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/geo-region/:id`,
    component: SettingsGeoRegionEdit,
  },
  {
    exact: true,
    name: "Game Provider List",
    path: `${rootPath}/general-settings/game-provider`,
    component: SettingsGameProviderList,
  },
  {
    exact: true,
    name: "Game Provider Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/game-provider/:id`,
    component: SettingsGameProviderEdit,
  },
  {
    exact: true,
    name: "Casino Game List",
    path: `${rootPath}/general-settings/casino-game`,
    component: SettingsCasinoGameList,
  },
  {
    exact: true,
    name: "Casino Game Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/casino-game/:id`,
    component: SettingsCasinoGameEdit,
  },
  {
    exact: true,
    name: "Attributes List",
    path: `${rootPath}/general-settings/attributes`,
    component: SettingsAttributesList,
  },
  {
    exact: true,
    name: "Attributes Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/attributes/:id`,
    component: SettingsAttributesEdit,
  },
  {
    exact: true,
    name: "Art Bundle Templates List",
    path: `${rootPath}/general-settings/art-bundle-template`,
    component: SettingsArtBundleTemplatesList,
  },
  {
    exact: true,
    name: "Art Bundle Templates Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/art-bundle-template/:id`,
    component: SettingsArtBundleTemplatesEdit,
  },
  {
    exact: true,
    name: "Art Bundles List",
    path: `${rootPath}/general-settings/art-bundles`,
    component: SettingsArtBundlesList,
  },
  {
    exact: true,
    name: "Art Bundles Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/art-bundles/:id`,
    component: SettingsArtBundleEdit,
  },
  {
    exact: true,
    name: "Reward Bundles List",
    path: `${rootPath}/loyalty/reward-bundles`,
    component: SettingsRewardBundlesList,
  },
  {
    exact: true,
    name: "Reward Bundles Edit",
    canGoBack: true,
    path: `${rootPath}/loyalty/reward-bundles/:id`,
    component: SettingsRewardBundlesEdit,
  },
  {
    exact: true,
    name: "Prize Templates List",
    path: `${rootPath}/general-settings/prize-templates`,
    component: SettingsPrizeTemplateList,
  },
  {
    exact: true,
    name: "Prize Template Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/prize-templates/:id`,
    component: SettingsPrizeTemplateEdit,
  },
  {
    exact: true,
    name: "Scope Usage Limits List",
    path: `${rootPath}/general-settings/limits`,
    component: SettingsScopeUsageLimitsList,
  },
  {
    exact: true,
    name: "Scope Usage Limit Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/limits/:id`,
    component: SettingsScopeUsageLimitsEdit,
  },
  {
    exact: true,
    name: "Scope Usage Instances List",
    path: `${rootPath}/general-settings/instances`,
    component: SettingsScopeUsageInstancesList,
  },
  {
    exact: true,
    name: "Scope Usage Associations List",
    path: `${rootPath}/general-settings/associations`,
    component: SettingsScopeUsageAssociationsList,
  },

  {
    exact: true,
    name: "HappyHour Definitions List",
    path: `${rootPath}/general-settings/happy-hour/definitions/:tenantId`,
    component: SettingsHappyHourDefinitionsList,
  },
  {
    exact: true,
    name: "HappyHour Definitions Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/happy-hour/definitions/:tenantId/:id`,
    component: SettingsHappyHourDefinitionsEdit,
  },
  {
    exact: true,
    name: "Jackpot Groups List",
    path: `${rootPath}/general-settings/jackpot-group`,
    component: SettingsJackpotGroupsList,
  },
  {
    exact: true,
    name: "Jackpot Groups Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/jackpot-group/:id`,
    component: SettingsJackpotGroupsEdit,
  },
  {
    exact: true,
    name: "Banner List",
    path: `${rootPath}/general-settings/banner`,
    component: SettingsBannerList,
  },
  {
    exact: true,
    name: "Banner Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/banner/:id`,
    component: SettingsBannerEdit,
  },
  {
    exact: true,
    name: "Banner Template List",
    path: `${rootPath}/general-settings/template-banner`,
    component: SettingsBannerTemplateList,
  },
  {
    exact: true,
    name: "Banner Template Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/template-banner/:id`,
    component: SettingsBannerTemplateEdit,
  },
  {
    exact: true,
    name: "Promotion List",
    path: `${rootPath}/general-settings/promotion`,
    component: SettingsPromotionList,
  },
  {
    exact: true,
    name: "Promotion Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/promotion/:id`,
    component: SettingsPromotionEdit,
  },
  {
    exact: true,
    name: "Promotion Template List",
    path: `${rootPath}/general-settings/template-promotion`,
    component: SettingsPromotionTemplateList,
  },
  {
    exact: true,
    name: "Promotion Template Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/template-promotion/:id`,
    component: SettingsPromotionTemplateEdit,
  },
  {
    exact: true,
    name: "Shop Item List",
    path: `${rootPath}/general-settings/shop-item`,
    component: SettingsShopItemList,
  },
  {
    exact: true,
    name: "Shop Item Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/shop-item/:id`,
    component: SettingsShopItemEdit,
  },
  {
    exact: true,
    name: "Shop Item Template List",
    path: `${rootPath}/general-settings/template-shop-item`,
    component: SettingsShopItemTemplateList,
  },
  {
    exact: true,
    name: "Shop Item Template Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/template-shop-item/:id`,
    component: SettingsShopItemTemplateEdit,
  },
  {
    exact: true,
    name: "Bonus Preset List",
    path: `${rootPath}/general-settings/bonus-preset`,
    component: SettingsBonusPresetList,
  },
  {
    exact: true,
    name: "Bonus Preset Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/bonus-preset/:id`,
    component: SettingsBonusPresetEdit,
  },
  {
    exact: true,
    name: "Missions Objectives List",
    path: `${rootPath}/general-settings/objectives-templates`,
    component: SettingsMissionsObjectivesList,
  },
  {
    exact: true,
    name: "Missions Objectives Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/objectives-templates/:id`,
    component: SettingsMissionsObjectivesEdit,
  },
  {
    exact: true,
    name: "Missions Templates List",
    path: `${rootPath}/general-settings/missions-templates`,
    component: SettingsMissionsTemplatesList,
  },
  {
    exact: true,
    name: "Missions Templates Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/missions-templates/:id`,
    component: SettingsMissionsTemplatesEdit,
  },
  {
    exact: true,
    name: "Wheel Setups Templates List",
    path: `${rootPath}/general-settings/wheel/setups-templates`,
    component: SettingsWheelSetupsTemplatesList,
  },
  {
    exact: true,
    name: "Wheel Setups Templates Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/wheel/setups-templates/:id`,
    component: SettingsWheelSetupsTemplatesEdit,
  },
  {
    exact: true,
    name: "Wheel Products Templates List",
    path: `${rootPath}/general-settings/wheel/products-templates`,
    component: SettingsWheelProductsTemplatesList,
  },
  {
    exact: true,
    name: "Wheel Values Templates List",
    path: `${rootPath}/general-settings/wheel/values-templates`,
    component: SettingsWheelValuesTemplatesList,
  },
  {
    exact: true,
    name: "Wheel Multipliers Templates List",
    path: `${rootPath}/general-settings/wheel/multipliers-templates`,
    component: SettingsWheelMultipliersTemplatesList,
  },
  {
    exact: true,
    name: "Wheel Puzzles Templates List",
    path: `${rootPath}/general-settings/wheel/puzzles-templates`,
    component: SettingsWheelPuzzlesTemplatesList,
  },

  {
    exact: true,
    name: "Bonus List",
    canGoBack: false,
    path: `${rootPath}/general-settings/bonus-list`,
    component: SettingsBonusList,
  },

  {
    exact: true,
    name: "Terms Of Service List",
    path: `${rootPath}/general-settings/terms-of-service`,
    component: SettingsTermsOfServiceList,
  },
  {
    exact: true,
    name: "Terms Of Service Edit",
    canGoBack: true,
    path: `${rootPath}/general-settings/terms-of-service/:id`,
    component: SettingsTermsOfServiceEdit,
  },

  {
    exact: true,
    name: "ACL Tenants",
    path: `${rootPath}/acl/tenants`,
    component: AclTenants,
  },
  {
    exact: true,
    name: "Edit Tenant",
    path: `${rootPath}/acl/tenant/:id`,
    canGoBack: true,
    component: AclTenantsGeneralSettings,
  },
  {
    exact: true,
    name: "ACL Users",
    path: `${rootPath}/acl/users`,
    component: AclUsersList,
  },
  {
    exact: true,
    name: "ACL User Details",
    path: `${rootPath}/acl/user/:userId`,
    canGoBack: true,
    component: AclUserDetails,
  },
  {
    exact: true,
    name: "ACL Users Groups",
    path: `${rootPath}/acl/users-groups`,
    component: AclGroupsList,
  },
  {
    exact: true,
    name: "ACL Users Group Details",
    path: `${rootPath}/acl/users-group/:groupId`,
    canGoBack: true,
    component: AclGroupDetails,
  },
  {
    exact: true,
    name: "ACL Api Tokens",
    path: `${rootPath}/acl/api-tokens`,
    component: AclApiTokenList,
  },
  {
    exact: true,
    name: "ACL Api Token Details",
    path: `${rootPath}/acl/api-token/:id`,
    canGoBack: true,
    component: AclApiTokenDetails,
  },
  {
    exact: true,
    name: "ACL Entity Groups List",
    path: `${rootPath}/acl/entity-groups`,
    component: AclEntityGroupsList,
  },
  {
    exact: true,
    name: "ACL Entity Groups List",
    path: `${rootPath}/acl/entity-group/:id`,
    canGoBack: true,
    component: AclEntityGroupsDetails,
  },
  {
    exact: true,
    name: "ACL Locations List",
    path: `${rootPath}/acl/locations`,
    component: AclLocationsList,
  },
  {
    exact: true,
    name: "ACL Location Details",
    path: `${rootPath}/acl/location/:id`,
    canGoBack: true,
    component: AclLocationsDetails,
  },
  {
    exact: true,
    name: "ACL Sites List",
    path: `${rootPath}/acl/sites`,
    component: AclSitesList,
  },
  {
    exact: true,
    name: "ACL Site Details",
    path: `${rootPath}/acl/site/:id`,
    canGoBack: true,
    component: AclSiteDetails,
  },
  {
    exact: true,
    name: "ACL Domains List",
    path: `${rootPath}/acl/domains`,
    canGoBack: false,
    component: AclDomainsList,
  },
  {
    exact: true,
    name: "ACL Domain Details",
    path: `${rootPath}/acl/domain/:id`,
    canGoBack: true,
    component: AclDomainDetail,
  },
  {
    exact: true,
    name: "ACL Users Groups",
    path: `${rootPath}/acl/groups`,
    component: AclGroupsList,
  },
  {
    exact: true,
    name: "ACL Users Group Details",
    path: `${rootPath}/acl/groups/:groupId`,
    canGoBack: true,
    component: AclGroupDetails,
  },
  {
    exact: true,
    name: "ACL Permissions",
    path: `${rootPath}/acl/permissions`,
    component: AclPermissions,
  },
  {
    exact: true,
    name: "ACL Roles",
    path: `${rootPath}/acl/roles`,
    component: AclRoles,
  },
  {
    exact: true,
    name: "ACL Role Details",
    path: `${rootPath}/acl/role/:roleId`,
    canGoBack: true,
    component: AclRoleDetails,
  },
  {
    exact: true,
    name: "ACL Rules",
    path: `${rootPath}/acl/rules`,
    component: AclRulesList,
  },
  {
    exact: true,
    name: "ACL Rule Details",
    path: `${rootPath}/acl/rule/:ruleId`,
    canGoBack: true,
    component: AclRuleDetails,
  },
  {
    exact: true,
    name: "Player Partial Accounts",
    path: `${rootPath}/partial-accounts/search`,
    component: PlayerPartialAccounts,
  },
  {
    exact: true,
    name: "Fraud & Risks (Alerts)",
    path: `${rootPath}/risk-performance-monitor/alerts`,
    component: RiskAndPerformanceAlerts,
  },
  {
    exact: true,
    name: "Due Diligence Queue",
    path: `${rootPath}/risk-performance-monitor/due-diligence`,
    component: DueDiligenceQueue,
  },
  {
    exact: true,
    name: "Documents Verification Queue",
    path: `${rootPath}/risk-performance-monitor/documents-verification-queue`,
    component: DocumentsVerificationQueue,
  },
  {
    exact: true,
    name: "Clear Closed Accounts",
    path: `${rootPath}/risk-performance-monitor/clear-closed-accounts`,
    component: ClearClosedAccounts,
  },
  {
    exact: true,
    name: "Bulk Tool - Wallet Cleanup",
    path: `${rootPath}/bulk-tools/wallet-cleanup`,
    component: BulkToolsDeduction,
  },
  {
    exact: true,
    name: "Bulk Tool - Grant Bonus",
    path: `${rootPath}/bulk-tools/grant-bonus`,
    component: BulkToolsGrantBonus,
  },
  {
    exact: true,
    name: "Bulk Tool - Grant Prizes",
    path: `${rootPath}/bulk-tools/grant-prizes`,
    component: BulkToolsGrantPrizes,
  },
  {
    exact: true,
    name: "Bulk Tool - Grant Player Tag",
    path: `${rootPath}/bulk-tools/grant-player-tags`,
    component: BulkToolsGrantPlayerTag,
  },
  {
    exact: true,
    name: "Bulk Tool - Update Affiliate Info",
    path: `${rootPath}/bulk-tools/update-affiliate-info`,
    component: BulkToolsUpdateAffiliateInfo,
  },
  {
    exact: true,
    name: "Player Vouchers Details",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/voucher/:voucherId`,
    canGoBack: true,
    layout: "CustomerLayout",
    component: PlayerVoucherDetails,
  },
  {
    exact: true,
    name: "Transactions",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/transaction-search`,
    layout: "CustomerLayout",
    component: PlayerTransactionsSearch,
  },
  {
    exact: true,
    name: "Transactions",
    path: `${rootPath}/transactions/search`,
    component: TransactionsSearch,
  },
  {
    exact: true,
    name: "Thank you",
    path: `${rootPath}/onjn/sign/auth/thank-you`,
    component: OnjnSignAuthThankYou,
  },
  {
    exact: true,
    name: "Cron Logs",
    path: `${rootPath}/onjn/cron-logs/search`,
    component: CronLogsSearch,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Game Type",
    path: `${rootPath}/onjn/game-type/search`,
    component: GameTypeSearch,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Game Profit",
    path: `${rootPath}/onjn/game-profit/search`,
    component: GameProfitSearch,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Jackpot Winnings",
    path: `${rootPath}/onjn/jackpot-winnings/search`,
    component: JackpotWinnings,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Relevant Events (WIP)",
    path: `${rootPath}/onjn/relevant-events/search`,
    component: RelevantEvents,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Exclusion Report",
    path: `${rootPath}/onjn/exclusion-report/search`,
    component: ExclusionReport,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Wallet Adjustment",
    path: `${rootPath}/onjn/wallet-adjustment/search`,
    component: WalletAdjustment,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Promotions",
    path: `${rootPath}/onjn/promotions/search`,
    component: Promotions,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Game Sessions",
    path: `${rootPath}/onjn/game-sessions/search`,
    component: GameSessions,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Report of Export (WIP)",
    path: `${rootPath}/onjn/report-export/search`,
    component: ReportExport,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Report requests (WIP)",
    path: `${rootPath}/onjn/requested-reports/search`,
    component: RequestedReports,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Backup Files",
    path: `${rootPath}/income-access/backup-files`,
    component: BackupFiles,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Upload Log",
    path: `${rootPath}/income-access/upload-log`,
    component: UploadLog,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Players Search",
    path: `${rootPath}/players/search`,
    component: PlayersSearch,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Player Overview",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/overview`,
    layout: "CustomerLayout",
    component: PlayerOverview,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Player Info",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/info`,
    layout: "CustomerLayout",
    component: PlayerInfo,
  },
  {
    exact: true,
    name: "Player Registration Details",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/registrationDetails`,
    layout: "CustomerLayout",
    component: PlayerRegistrationDetails,
  },
  {
    exact: true,
    name: "Timeline",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/timeline`,
    layout: "CustomerLayout",
    component: PlayerTimeline,
  },
  {
    exact: true,
    name: "Prizes",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/prizes`,
    layout: "CustomerLayout",
    component: PlayerPrizes,
  },
  {
    exact: true,
    name: "Prizes Details",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/prizes/:id`,
    layout: "DetailsLayout",
    component: PlayerPrizesDetails,
  },
  {
    exact: true,
    name: "Account Management",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/account-management`,
    layout: "CustomerLayout",
    component: PlayerAccountManagement,
  },
  {
    exact: true,
    name: "Tags",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/tags`,
    layout: "CustomerLayout",
    component: PlayerTags,
  },
  {
    exact: true,
    name: "Player Tag History",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/tags-history/:tagId`,
    layout: "DetailsLayout",
    component: PlayerTagHistory,
  },
  {
    exact: true,
    name: "Restrictions and Limits",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/restrictions-and-limits`,
    layout: "CustomerLayout",
    component: PlayerRestrictionsAndLimits,
  },
  {
    exact: true,
    name: "Devices",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/devices`,
    layout: "CustomerLayout",
    component: PlayerDeviceSearch,
  },
  {
    exact: true,
    name: "Fraud",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/fraud`,
    layout: "CustomerLayout",
    component: PlayerFraud,
  },
  {
    exact: true,
    name: "Games Activity",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/games-activity`,
    layout: "CustomerLayout",
    component: PlayerGamesActivity,
  },
  {
    exact: true,
    name: "Game Activity Details",
    path: `${rootPath}/player-game-activity/:activityType/:clientId/:playerId/:activityId`,
    layout: "DetailsLayout",
    component: PlayerGamesActivityDetails,
  },
  {
    exact: true,
    name: "Comments",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/comments`,
    layout: "CustomerLayout",
    component: PlayerComments,
  },
  {
    exact: true,
    name: "Wallets",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/wallets`,
    layout: "CustomerLayout",
    component: PlayerWallets,
  },
  {
    exact: true,
    name: "Vouchers",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/vouchers`,
    layout: "CustomerLayout",
    component: PlayerVouchers,
  },
  {
    exact: true,
    name: "Vouchers Details",
    path: `${rootPath}/player-voucher-details/:clientId/:playerId/:voucherId/:id`,
    layout: "DetailsLayout",
    component: PlayerVoucherDetails,
  },
  {
    exact: true,
    name: "Loyalty" + " (WIP)",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/loyalty`,
    layout: "CustomerLayout",
    component: PlayerLoyalty,
  },
  {
    exact: true,
    name: "Inbox" + " (WIP)",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/inbox`,
    layout: "CustomerLayout",
    component: PlayerInbox,
  },
  {
    exact: true,
    name: "Bonuses",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/bonus-search`,
    layout: "CustomerLayout",
    component: PlayerBonusSearch,
  },
  {
    exact: true,
    name: "Payments",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/payment-search`,
    layout: "CustomerLayout",
    component: PlayerPaymentSearch,
  },
  {
    exact: true,
    name: "Payment Methods",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/payment-methods`,
    layout: "CustomerLayout",
    component: PlayerCardsSearch,
  },
  {
    exact: true,
    name: "Player Login History",
    path: `${rootPath}/player/login-history-search`,
    component: PlayerLoginHistorySearch,
  },
  {
    exact: true,
    name: "Login History",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/login-history`,
    layout: "CustomerLayout",
    component: PlayerLoginHistorySearch,
  },
  {
    exact: true,
    name: "Documents",
    path: `${rootPath}/player/documents-search`,
    component: PlayerDocumentsSearch,
  },
  {
    exact: true,
    name: "Documents",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/documents-search`,
    layout: "CustomerLayout",
    component: PlayerDocumentsSearch,
  },
  {
    exact: true,
    name: "Document History",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/document-history/:documentId`,
    layout: "DetailsLayout",
    component: PlayerDocumentHistory,
  },

  {
    exact: true,
    name: "Player Missions",
    path: `${rootPath}/player/:clientId/:siteId/:playerId/player-missions`,
    layout: "CustomerLayout",
    component: CustomerAccountsPlayerMissions,
  },

  {
    exact: true,
    name: "Payments Search",
    path: `${rootPath}/payments/search`,
    component: PaymentsSearch,
  },
  {
    exact: true,
    name: "Payment",
    canGoBack: true,
    path: `${rootPath}/payments/search/:clientId/:playerId/:paymentId`,
    component: Payment,
  },
  {
    exact: true,
    name: "Payment",
    canGoBack: true,
    path: `${rootPath}/player/:clientId/:siteId/:playerId/payment-search/:paymentId`,
    layout: "CustomerLayout",
    component: Payment,
  },
  {
    exact: true,
    name: "Tax Reports" + " (WIP)",
    path: `${rootPath}/payments/tax-report`,
    component: PaymentsTaxReport,
  },
  {
    exact: true,
    name: "Winner Fun Report",
    path: `${rootPath}/payments/winner-fun-report`,
    component: PaymentsWinnerFunReport,
  },
  {
    exact: true,
    name: "Player Bonus Search",
    path: `${rootPath}/player-bonus/search`,
    component: BonusSearch,
  },
  {
    exact: true,
    name: "Player Bonus",
    path: `${rootPath}/player-bonus/:clientId/:playerId/:bonusType/:bonusId`,
    layout: "DetailsLayout",
    component: PlayerBonus,
  },
  {
    exact: true,
    name: "Retail Tickets",
    path: `${rootPath}/retail/tickets`,
    component: RetailTickets,
  },
  {
    exact: true,
    name: "Retail Tickets Settings",
    path: `${rootPath}/retail/settings/:tenantId`,
    component: RetailTicketsSettings,
  },
  {
    exact: true,
    name: "Media Library",
    path: `${rootPath}/media-library`,
    component: MediaLibrary,
  },
  {
    exact: true,
    name: "Video Reels",
    path: `${rootPath}/video-reels`,
    component: VideoReels,
  },
  {
    exact: true,
    name: "Lotto Refresh RaspBerry PI",
    path: `${rootPath}/lotto-rp-refresh`,
    component: LottoRaspberryPI,
  },
  {
    exact: true,
    name: "Avatars",
    path: `${rootPath}/players/avatars/:tenantId`,
    component: PlayersAvatars,
  },
  {
    exact: true,
    name: "Evolution Campaigns",
    path: `${rootPath}/evolution/campaigns`,
    component: EvolutionCampaigns,
  },
  {
    exact: true,
    name: "Evolution Campaigns",
    path: `${rootPath}/evolution/campaigns/:id`,
    canGoBack: true,
    component: EvolutionCampaignEdit,
  },
  {
    exact: true,
    name: "Tournaments Templates",
    path: `${rootPath}/missions-tournaments/templates-missions`,
    component: MissionsTournamentsMissionsTemplates,
  },
  {
    exact: true,
    name: "Edit Tournament Template",
    path: `${rootPath}/missions-tournaments/templates-missions/:tenantId/:id`,
    canGoBack: true,
    component: MissionsTournamentsTemplateMissionEdit,
  },
  {
    exact: true,
    name: "Tournament Leaderboard",
    path: `${rootPath}/missions-tournaments/missions/leaderboard/:tenantId/:id`,
    canGoBack: true,
    component: MissionsTournamentsMissionRanking,
  },
  {
    exact: true,
    name: "Rankings Leaderboard",
    path: `${rootPath}/missions-tournaments/rankings/leaderboard/:tenantId/:id`,
    canGoBack: true,
    component: MissionsTournamentsMissionsRankingView,
  },
  {
    exact: true,
    name: "Ranking Results",
    path: `${rootPath}/missions-tournaments/rankings`,
    component: MissionsTournamentsMissionsRanking,
  },
  {
    exact: true,
    name: "Tournaments",
    path: `${rootPath}/missions-tournaments/missions`,
    component: MissionsTournamentsMissions,
  },
  {
    exact: true,
    name: "Edit Tournament",
    path: `${rootPath}/missions-tournaments/missions/:tenantId/:id`,
    canGoBack: true,
    component: MissionsTournamentsMissionEdit,
  },

  {
    exact: true,
    name: "Player Missions",
    path: `${rootPath}/missions-tournaments/player-missions`,
    component: MissionsTournamentsPlayerMissions,
  },

  {
    exact: true,
    name: "Objectives Templates",
    path: `${rootPath}/missions-tournaments/templates-objectives`,
    component: MissionsTournamentsObjectivesTemplates,
  },
  {
    exact: true,
    name: "Edit Objective Template",
    path: `${rootPath}/missions-tournaments/templates-objectives/:tenantId/:id`,
    canGoBack: true,
    component: MissionsTournamentsObjectiveTemplateEdit,
  },
  {
    exact: true,
    name: "Objectives",
    path: `${rootPath}/missions-tournaments/objectives`,
    component: MissionsTournamentsObjectives,
  },
  {
    exact: true,
    name: "Edit Objective",
    path: `${rootPath}/missions-tournaments/objectives/:tenantId/:id`,
    canGoBack: true,
    component: MissionsTournamentsObjectiveEdit,
  },
  {
    exact: true,
    name: "Challenge Missions",
    path: `${rootPath}/missions-tournaments/tournament-challenge-groups`,
    component: MissionsTournamentsTournamentGroups,
  },

  {
    exact: true,
    name: "Bonus Withdrawal Missions",
    path: `${rootPath}/missions-tournaments/tournament-bonus-withdrawal-groups`,
    component: MissionsTournamentsTournamentGroups,
  },

  {
    exact: true,
    name: "Casino Tournaments",
    path: `${rootPath}/missions-tournaments/tournament-casino-groups`,
    component: MissionsTournamentsTournamentGroups,
  },
  {
    exact: true,
    name: "Sportsbook Tournaments",
    path: `${rootPath}/missions-tournaments/tournament-sport-groups`,
    component: MissionsTournamentsTournamentGroups,
  },
  {
    exact: true,
    name: "Tournament Challenge Group Edit",
    path: `${rootPath}/missions-tournaments/tournament-challenge-groups/:tenantId/:id`,
    canGoBack: true,
    component: MissionsTournamentsTournamentGroupEdit,
  },

  {
    exact: true,
    name: "Tournament Bonus Withdrawal Group Edit",
    path: `${rootPath}/missions-tournaments/tournament-bonus-withdrawal-groups/:tenantId/:id`,
    canGoBack: true,
    component: MissionsTournamentsTournamentGroupEdit,
  },
  {
    exact: true,
    name: "Tournament Casino Group Edit",
    path: `${rootPath}/missions-tournaments/tournament-casino-groups/:tenantId/:id`,
    canGoBack: true,
    component: MissionsTournamentsTournamentGroupEdit,
  },
  {
    exact: true,
    name: "Tournament Sport Group Edit",
    path: `${rootPath}/missions-tournaments/tournament-sport-groups/:tenantId/:id`,
    canGoBack: true,
    component: MissionsTournamentsTournamentGroupEdit,
  },
  //TODO - MAIN SETTINGS
  {
    exact: true,
    name: "Main Settings",
    path: `${rootPath}/main-settings`,
    component: MainSettingsSeach,
  },
  {
    exact: true,
    name: "Main Settings Edit",
    path: `${rootPath}/main-settings/edit/:tenantId/:id`,
    component: MainSettingsEdit,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Notifications",
    path: `${rootPath}/player-notifications/list`,
    component: NotificationsSearch,
  },
  {
    exact: true,
    name: "Notification Templates",
    path: `${rootPath}/player-notifications/templates`,
    component: NotificationTemplatesSearch,
  },
  {
    exact: true,
    name: "Notification Template Edit",
    path: `${rootPath}/player-notifications/templates/edit/:tenantId/:id`,
    component: NotificationTemplatesEdit,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Loyalty Currency Groups",
    path: `${rootPath}/loyalty/currency-groups`,
    component: LoyaltyLoyaltyCurrencyManager,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Loyalty Currency Groups - sort by priority",
    path: `${rootPath}/loyalty/sort-currency-groups`,
    component: LoyaltyCurrencySortingGroupsEdit,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Edit Loyalty Currency Group",
    path: `${rootPath}/loyalty/currency-groups/group/:tenantId/:id`,
    component: LoyaltyLoyaltyCurrencyGroupEdit,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Loyalty Currencies",
    path: `${rootPath}/loyalty/currencies`,
    component: LoyaltyLoyaltyCurrencyManagerCurrencies,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Edit Loyalty Currency",
    path: `${rootPath}/loyalty/currencies/currency/:tenantId/:id`,
    component: LoyaltyLoyaltyCurrencyEdit,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Loyalty Shop Campaign Groups Manager",
    path: `${rootPath}/loyalty/campaign-groups`,
    component: LoyaltyCampaignGroupManager,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Loyalty Shop Campaign Manager",
    path: `${rootPath}/loyalty/campaigns`,
    component: LoyaltyCampaignManager,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Loyalty Missions",
    path: `${rootPath}/loyalty/missions`,
    component: LoyaltyLoyaltyMissions,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Edit Mission",
    path: `${rootPath}/loyalty/missions/mission/:tenantId/:id`,
    component: LoyaltyLoyaltyMission,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Loyalty Objectives",
    path: `${rootPath}/loyalty/objectives`,
    component: LoyaltyLoyaltyObjectives,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Edit Objective",
    path: `${rootPath}/loyalty/objectives/objective/:tenantId/:id`,
    component: LoyaltyLoyaltyObjective,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Loyalty Campaign Groups",
    path: `${rootPath}/missions-tournaments/loyalty/campaign-groups`,
    component: MissionTournamentsLoyaltyCampaignGroups,
  },
  {
    exact: true,
    name: "Edit Loyalty Shop Campaign Group",
    path: `${rootPath}/loyalty/campaign-groups/group/:tenantId/:id`,
    component: LoyaltyLoyaltyCampaignGroupEdit,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Edit Loyalty Shop Campaign",
    path: `${rootPath}/loyalty/campaigns/campaign/:tenantId/:id`,
    component: LoyaltyLoyaltyCampaignEdit,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Loyalty Rank Campaign Groups Manager",
    path: `${rootPath}/loyalty/rank-campaign-groups`,
    component: LoyaltyRankCampaignGroupManager,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Edit Rank Campaign Group",
    path: `${rootPath}/loyalty/rank-campaign-groups/group/:tenantId/:id`,
    component: LoyaltyRankCampaignGroupEdit,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Loyalty Rank Campaign Manager",
    path: `${rootPath}/loyalty/rank-campaigns`,
    component: LoyaltyRankCampaignManager,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Edit Loyalty Rank Campaign",
    path: `${rootPath}/loyalty/rank-campaigns/campaign/:tenantId/:id`,
    component: LoyaltyRankCampaignEdit,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Rank Titles",
    path: `${rootPath}/missions-tournaments/loyalty/rank-titles`,
    component: MissionTournamentsLoyaltyRankTitles,
  },
  {
    exact: true,
    name: "Rank Title Edit",
    path: `${rootPath}/missions-tournaments/loyalty/rank-systems/titles/:tenantId/:id`,
    component: MissionTournamentsLoyaltyRankTitleEdit,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Rank Campaign Edit",
    path: `${rootPath}/missions-tournaments/loyalty/rank-systems/campaigns/:tenantId/:id`,
    component: MissionTournamentsLoyaltyRankCampaignEdit,
    canGoBack: true,
  },
  {
    exact: true,
    name: "Landing Pages",
    path: `${rootPath}/landing/pages/:tenantId`,
    component: LandingPages,
  },
  {
    exact: true,
    name: "Landing Page Edit",
    path: `${rootPath}/landing/pages/:tenantId/:id`,
    canGoBack: true,
    component: LandingPagesEdit,
  },
  {
    exact: true,
    name: "Marketing Offers",
    path: `${rootPath}/landing/marketing-offerings`,
    component: LandingPagesMarketingOffers,
  },
  {
    exact: true,
    name: "Marketing Offer Edit",
    path: `${rootPath}/landing/marketing-offerings/:tenantId/:id`,
    canGoBack: true,
    component: LandingPagesMarketingOffersEdit,
  },
  {
    exact: true,
    name: "Winter Promo",
    path: `${rootPath}/promo/winter`,
    component: PromoWinter,
  },
  {
    exact: true,
    name: "Winter Prizes",
    path: `${rootPath}/promo/prizes-winter`,
    component: PromoWinterPrizes,
  },
  {
    exact: true,
    name: "Campaign Settings",
    path: `${rootPath}/promo/campaign-settings/:tenantId`,
    component: PromoSettings,
  },
  {
    exact: true,
    name: "Edit Winter Promo",
    path: `${rootPath}/promo/winter/:tenantId/:id`,
    canGoBack: true,
    component: PromoWinterEdit,
  },
  {
    exact: true,
    name: "Classes - Tags",
    path: `${rootPath}/bonuses/tags`,
    component: BonusesTagClasses,
  },
  {
    exact: true,
    name: "Campaign Tags",
    path: `${rootPath}/optimove/campaign-tags`,
    component: OptimoveCampaignTags,
  },
  {
    exact: true,
    name: "Scheduled Runs",
    path: `${rootPath}/optimove/scheduled-runs`,
    component: OptimoveScheduledRuns,
  },
  {
    exact: true,
    name: "Tagged Players",
    path: `${rootPath}/optimove/tagged-players`,
    component: OptimoveTaggedPlayers,
  },
  {
    exact: true,
    name: "Player Keys",
    path: `${rootPath}/wheel/player-wheel-keys`,
    component: WheelPlayerKeys,
  },
  {
    exact: true,
    name: "Player Tokens",
    path: `${rootPath}/wheel/player-wheel-tokens`,
    component: WheelPlayerTokens,
  },
  {
    exact: true,
    name: "Player Wheel Spins",
    path: `${rootPath}/wheel/player-wheel-spins`,
    component: PlayerWheelSpins,
  },
  {
    exact: true,
    name: "Aggregate Wheel Spins",
    path: `${rootPath}/wheel/agg-wheel-spins`,
    component: AggWheelSpins,
  },
  {
    exact: true,
    name: "RNG Ranges",
    path: `${rootPath}/wheel/probability-ranges`,
    component: WheelProbabilityRanges,
  },
  {
    exact: true,
    name: "RNG Range Edit",
    path: `${rootPath}/wheel/probability-ranges/:id`,
    canGoBack: true,
    component: WheelProbabilityRangesEdit,
  },
  {
    exact: true,
    name: "Core Eligibility",
    path: `${rootPath}/wheel/core-eligibility`,
    component: WheelCoreEligibility,
  },
  {
    exact: true,
    name: "Products",
    path: `${rootPath}/wheel/products/:tenantId`,
    component: WheelProducts,
  },
  {
    exact: true,
    name: "ProductEdit",
    path: `${rootPath}/wheel/product/:tenantId/:id`,
    component: WheelProductEdit,
  },
  {
    exact: true,
    name: "Values",
    path: `${rootPath}/wheel/values/:tenantId`,
    component: WheelValues,
  },
  {
    exact: true,
    name: "Multipliers",
    path: `${rootPath}/wheel/multipliers/:tenantId`,
    component: WheelMultipliers,
  },
  {
    exact: true,
    name: "Puzzles",
    path: `${rootPath}/wheel/puzzles/:tenantId`,
    component: WheelPuzzles,
  },
  {
    exact: true,
    name: "Prizes",
    path: `${rootPath}/wheel/prizes/:tenantId`,
    component: WheelPrizes,
  },
  {
    exact: true,
    name: "General Settings",
    path: `${rootPath}/wheel/settings/:tenantId`,
    component: WheelSettings,
  },
  {
    exact: true,
    name: "Wheel Setups",
    path: `${rootPath}/wheel/setups/:tenantId`,
    component: WheelSetups,
  },
  {
    exact: true,
    name: "Wheel Setup Edit",
    path: `${rootPath}/wheel/setups/:tenantId/:id`,
    canGoBack: true,
    component: WheelSetupsEdit,
  },
  {
    exact: true,
    name: "Player Level Setups",
    path: `${rootPath}/wheel/player-level-setups/:tenantId`,
    component: WheelPlayerLevelSetups,
  },
  {
    exact: true,
    name: "Player Level Setup Edit",
    path: `${rootPath}/wheel/player-level-setups/:tenantId/:id`,
    canGoBack: true,
    component: WheelPlayerLevelSetupsEdit,
  },
  {
    exact: true,
    name: "Data Sources",
    path: `${rootPath}/lobby/data-sources/:tenantId`,
    component: LobbyDataSources,
  },
  {
    exact: true,
    name: "Data Source Edit",
    path: `${rootPath}/lobby/data-sources/:tenantId/:id`,
    canGoBack: true,
    component: LobbyDataSourcesEdit,
  },
  {
    exact: true,
    name: "Pages",
    path: `${rootPath}/lobby/pages/:tenantId`,
    component: LobbyPages,
  },
  {
    exact: true,
    name: "Page Edit",
    path: `${rootPath}/lobby/pages/:tenantId/:id`,
    canGoBack: true,
    component: LobbyPagesEdit,
  },
  {
    exact: true,
    name: "Component Templates",
    path: `${rootPath}/lobby/component-templates`,
    component: LobbyComponentTemplates,
  },
  {
    exact: true,
    name: "Component Template Edit",
    path: `${rootPath}/lobby/component-templates/:id`,
    canGoBack: true,
    component: LobbyComponentTemplatesEdit,
  },
  {
    exact: true,
    name: "Bonus Preset",
    path: `${rootPath}/bonuses/bonus-presets`,
    component: BonusesFreeBets,
  },
  {
    exact: true,
    name: "Edit Bonus",
    canGoBack: true,
    path: `${rootPath}/bonuses/bonus-preset/:tenantId/:id`,
    component: BonusesFreeBetEdit,
  },
  {
    exact: true,
    name: "Bonus Popups",
    path: `${rootPath}/bonuses/popups`,
    component: BonusesPopups,
  },
  {
    exact: true,
    name: "Bonus Popup Edit",
    canGoBack: true,
    path: `${rootPath}/bonuses/popup/:tenantId/:id`,
    component: BonusesPopupEdit,
  },
  {
    exact: true,
    name: "Enrollment Campaigns",
    path: `${rootPath}/bonuses/enrollment-campaigns`,
    component: EnrollmentCampaigns,
  },
  {
    exact: true,
    name: "Enrollment Campaign Edit",
    canGoBack: true,
    path: `${rootPath}/bonuses/enrollment-campaign/:tenantId/:id`,
    component: EnrollmentCampaignsEdit,
  },
  {
    exact: true,
    name: "Voucher Campaigns",
    path: `${rootPath}/bonuses/campaigns`,
    component: Campaigns,
  },
  {
    exact: true,
    name: "Campaign Edit",
    canGoBack: true,
    path: `${rootPath}/bonuses/campaign/:tenantId/:id`,
    component: CampaignsEdit,
  },
  {
    exact: true,
    name: "Games",
    path: `${rootPath}/casino/games`,
    component: Games,
  },
  {
    exact: true,
    name: "Games Attributes Edit",
    canGoBack: true,
    path: `${rootPath}/casino/attributes-games/:tenantId/:id`,
    component: GamesAttributesEdit,
  },
  {
    exact: true,
    name: "Games Attributes",
    path: `${rootPath}/casino/attributes-games/:tenantId`,
    component: GamesAttributes,
  },
  {
    exact: true,
    name: "Games Data Sources",
    path: `${rootPath}/casino/data-sources-games`,
    component: GamesDataSources,
  },
  {
    exact: true,
    name: "Game Data Sources Edit",
    canGoBack: true,
    path: `${rootPath}/casino/data-sources-games/edit/:id`,
    component: GameDataSourcesEdit,
  },
  {
    exact: true,
    name: "Game Edit",
    canGoBack: true,
    path: `${rootPath}/casino/game/:id`,
    component: GamesEdit,
  },
  {
    exact: true,
    name: "Teams UI",
    path: `${rootPath}/winner-fun/teams-ui/:tenantId`,
    component: WinnerFunTeamsUI,
  },
  {
    exact: true,
    name: "Teams List",
    path: `${rootPath}/winner-fun/teams-list/:tenantId`,
    component: WinnerFunTeamsData,
  },
  {
    exact: true,
    name: "Team Edit",
    canGoBack: true,
    path: `${rootPath}/winner-fun/teams-list/:tenantId/:id`,
    component: WinnerFunTeamDataEdit,
  },
  {
    exact: true,
    name: "Calendar",
    path: `${rootPath}/winner-fun/calendar/:tenantId`,
    component: WinnerFunCalendar,
  },
  {
    exact: true,
    name: "Season / Event Edit",
    canGoBack: true,
    path: `${rootPath}/winner-fun/calendar/:tenantId/:id`,
    component: WinnerFunCalendarEdit,
  },
  {
    exact: true,
    name: "Win Coins",
    path: `${rootPath}/winner-fun/win-coins-systems/:tenantId`,
    component: WinnerFunWinCoinsSystems,
  },
  {
    exact: true,
    name: "Win Coins Edit",
    canGoBack: true,
    path: `${rootPath}/winner-fun/win-coins-systems/:tenantId/:id`,
    component: WinnerFunWinCoinsSystemsEdit,
  },
  {
    exact: true,
    name: "Referral Settings",
    path: `${rootPath}/winner-fun/referral-settings/:tenantId`,
    component: WinnerFunReferralSettings,
  },
  {
    exact: true,
    name: "Ranking Settings",
    path: `${rootPath}/winner-fun/ranking-settings/:tenantId`,
    component: WinnerFunRankingSettings,
  },
  {
    exact: true,
    name: "Super Banner",
    path: `${rootPath}/winner-fun/super-banner/:tenantId`,
    component: WinnerFunSuperBanner,
  },
  {
    exact: true,
    name: "Ranking",
    canGoBack: true,
    path: `${rootPath}/winner-fun/ranking/:type/:id`,
    component: WinnerFunCalendarRanking,
  },
  {
    exact: true,
    name: "Claim Report",
    path: `${rootPath}/winner-fun/claim-report`,
    component: WinnerFunClaimReport,
  },
  {
    exact: true,
    name: "RAF Report",
    path: `${rootPath}/winner-fun/raf-report`,
    component: WinnerFunRAFReport,
  },
  {
    exact: true,
    name: "Player Nicknames",
    path: `${rootPath}/winner-fun/players`,
    component: WinnerFunNicknames,
  },
  {
    exact: true,
    name: "Teams ranking",
    path: `${rootPath}/winner-fun/teams-ranking/:tenantId/:type/:typeId`,
    component: WinnerFunTeamsRanking,
  },
  {
    exact: true,
    name: "Team ranking",
    canGoBack: true,
    path: `${rootPath}/winner-fun/team-ranking/:tenantId/:type/:typeId/:id`,
    component: WinnerFunTeamRanking,
  },
  {
    exact: true,
    name: "Maintenance",
    path: `${rootPath}/maintenance/:tenantId`,
    component: Maintenance,
  },
  {
    exact: true,
    name: "Sports",
    path: `${rootPath}/sports/:tenantId`,
    component: Sports,
  },
  {
    exact: true,
    name: "UK Settings Markets",
    path: `${rootPath}/uk-settings-markets/:tenantId`,
    component: UKSettingsMarkets,
  },
  {
    exact: true,
    name: "Markets Groups Sorter",
    path: `${rootPath}/markets-groups-sorter/:tenantId`,
    component: BetsMarketSorter,
  },
  {
    exact: true,
    name: "Crests",
    path: `${rootPath}/crests/:tenantId`,
    component: Crests,
  },
  {
    exact: true,
    name: "Highlights",
    path: `${rootPath}/highlights/:tenantId`,
    component: Highlights,
  },
  {
    exact: true,
    name: "Entities",
    path: `${rootPath}/entities/:tenantId`,
    component: Entities,
  },
  {
    exact: true,
    name: "Calendar (Hot Days)",
    path: `${rootPath}/calendar-hot/:tenantId`,
    component: CalendarHot,
  },

  {
    exact: true,
    name: "Bets",
    path: `${rootPath}/bets/:tenantId`,
    component: Bets,
  },
  {
    exact: true,
    name: "Outcomes",
    path: `${rootPath}/outcomes/:tenantId`,
    component: Outcomes,
  },
  {
    exact: true,
    name: "Sport Radar Stats",
    path: `${rootPath}/sr/:tenantId`,
    component: SportRadar,
  },
  {
    exact: true,
    name: "Settings",
    path: `${rootPath}/settings/:tenantId`,
    component: Settings,
  },
  {
    exact: true,
    name: "Homepage Leagues List",
    path: `${rootPath}/leagues/:tenantId`,
    component: HomepageLeagues,
  },
  {
    exact: true,
    name: "Homepage News",
    path: `${rootPath}/news/:tenantId`,
    component: HomepageNews,
  },
  {
    exact: true,
    name: "Homepage Groups",
    path: `${rootPath}/homepage-groups/:tenantId`,
    component: HomepageGroups,
  },
  {
    exact: true,
    name: "Homepage Groups Images",
    path: `${rootPath}/homepage-groups-images/:tenantId`,
    component: HomepageGroupsImages,
  },
  {
    exact: true,
    name: "Homepage Sports Navigation Bar",
    path: `${rootPath}/homepage-sports-navbar/:tenantId`,
    component: HomepageSports,
  },
  {
    exact: true,
    name: "Live Top League",
    path: `${rootPath}/live-top-league/:tenantId`,
    component: LiveTopLeague,
  },
  {
    exact: true,
    name: "Customize Navigation Bar",
    path: `${rootPath}/customize-navbar/:tenantId`,
    component: CustomizeNavBar,
  },
  {
    exact: true,
    name: "Error Log",
    path: `${rootPath}/error_log`,
    component: ErrorLogs,
  },
  {
    exact: true,
    name: "Audit Log",
    path: `${rootPath}/audit_logs`,
    component: AuditLogs,
  },
  {
    exact: true,
    name: "Lotto Promoted",
    path: `${rootPath}/lotto-promoted/:tenantId`,
    component: LottoPromoted,
  },
  {
    exact: true,
    name: "Bonus Evaluate",
    canGoBack: true,
    path: `${rootPath}/bonus-evaluate/:id`,
    component: BonusEvaluate,
  },
  {
    exact: true,
    name: "Ticket Builder",
    path: `${rootPath}/bonus-ticket-builder`,
    component: BonusTicketBuilder,
  },
  {
    exact: true,
    name: "Bonus Ticket List",
    path: `${rootPath}/bonus-ticket-list`,
    component: BonusTicketList,
  },
  {
    exact: true,
    name: "Settled Tickets List",
    path: `${rootPath}/bonus-tickets-settled-list`,
    component: BonusTicketsSettled,
  },
  {
    exact: true,
    name: "Opened Tickets List",
    path: `${rootPath}/bonus-tickets-opened-list`,
    component: BonusTicketsOpened,
  },
  {
    exact: true,
    name: "Bonus Rules List",
    path: `${rootPath}/bonus-rules-list`,
    component: BonusRulesList,
  },

  {
    exact: true,
    name: "Digitain Tickets",
    path: `${rootPath}/digitain/tickets`,
    component: DigitainTicketsList,
  },
  {
    exact: true,
    canGoBack: true,
    name: "Digitain Ticket Info",
    path: `${rootPath}/digitain/ticket/:id`,
    component: DigitainTicketInfo,
  },

  {
    exact: true,
    name: "Digitain Players",
    path: `${rootPath}/digitain/players`,
    component: DigitainPlayersList,
  },
  {
    exact: true,
    name: "Digitain Transactions",
    path: `${rootPath}/digitain/transactions`,
    component: DigitainTransactionsList,
  },
  {
    exact: true,
    name: "Digitain Initial Data List",
    path: `${rootPath}/digitain/initial-data`,
    component: DigitainInitialDataList,
  },

  {
    exact: true,
    name: "Digitain Winner Fun Tickets",
    path: `${rootPath}/digitain-wf/tickets`,
    component: DigitainWinnerFunTicketsList,
  },
  {
    exact: true,
    canGoBack: true,
    name: "Digitain Ticket Info",
    path: `${rootPath}/digitain-wf/ticket/:id`,
    component: DigitainWinnerFunTicketInfo,
  },
  {
    exact: true,
    name: "Digitain Winner Fun Players",
    path: `${rootPath}/digitain-wf/players`,
    component: DigitainWinnerFunPlayersList,
  },
  {
    exact: true,
    name: "Digitain Winner Fun Transactions",
    path: `${rootPath}/digitain-wf/transactions`,
    component: DigitainWinnerFunTicketsTransactionsList,
  },

  {
    exact: true,
    name: "Happy Hours Definitions",
    path: `${rootPath}/happy-hours-definitions/:tenantId`,
    component: HappyHoursDefinitions,
  },
  {
    exact: true,
    name: "Happy Hours Events",
    path: `${rootPath}/happy-hours-events/:tenantId`,
    component: HappyHoursEvents,
  },
  {
    exact: true,
    name: "Happy Hours Definition Edit",
    path: `${rootPath}/happy-hours-definition/:tenantId/:id`,
    canGoBack: true,
    component: HappyHoursDefinitionEdit,
  },
  {
    exact: true,
    name: "Happy Hours Awards",
    path: `${rootPath}/happy-hours-awards`,
    component: HappyHoursAwards,
  },
  {
    exact: true,
    name: "Slot Games Background Color",
    path: `${rootPath}/slots-bg-color/:tenantId`,
    component: SlotsBGColor,
  },
  {
    exact: true,
    name: "Users",
    path: `${rootPath}/users/:tenantId`,
    component: Users,
  },
  {
    exact: true,
    name: "Super Banner Virtual Games",
    path: `${rootPath}/virtual-super-banner/:tenantId`,
    component: SuperBannerVirtual,
  },
  {
    exact: true,
    name: "Super Banner Lotto",
    path: `${rootPath}/lotto-super-banner/:tenantId`,
    component: SuperBannerLotto,
  },
  {
    exact: true,
    name: "Super Banner Casino",
    path: `${rootPath}/casino-super-banner/:tenantId`,
    component: SuperBannerCasino,
  },
  {
    exact: true,
    name: "Homepage Components",
    path: `${rootPath}/homepage-components/:tenantId`,
    component: Components,
  },
  {
    exact: true,
    name: "Edit Component Source",
    path: `${rootPath}/components/:tenantId/:id`,
    canGoBack: true,
    component: ComponentEdit,
  },
  {
    exact: true,
    name: "New Message",
    path: `${rootPath}/comms/new-message`,
    component: NewMessage,
  },
  {
    exact: true,
    name: "Player Messages List",
    path: `${rootPath}/comms/player-messages`,
    component: PlayerMessages,
  },
  {
    exact: true,
    name: "Notifications...",
    path: `${rootPath}/comms/notifications`,
    component: Notifications,
  },
  // {
  //   exact: true,
  //   name: "Player History",
  //   path: `${rootPath}/player-history/:tenantId/:playerId`,
  //   component: PlayerHistory,
  // },
  {
    exact: true,
    name: "Create Brand",
    path: `${rootPath}/white-label/create-brand`,
    component: WhiteLabelCreateBrand,
  },
  {
    exact: true,
    name: "Domain Administration",
    path: `${rootPath}/white-label/domain-administration`,
    component: WhiteLabelDomainAdministration,
  },
  {
    exact: true,
    name: "Content Management Deployment",
    path: `${rootPath}/white-label/content-deployment`,
    component: WhiteLabelContentDeployment,
  },
  {
    exact: true,
    name: "Wizard Sessions",
    path: `${rootPath}/white-label/sessions`,
    component: WhiteLabelSessions,
  },
  {
    exact: true,
    name: "Definitions List",
    canGoBack: false,
    path: `${rootPath}/white-label/content-management/definitions-list`,
    component: ContentManagementDefinitionsList,
  },
  {
    exact: true,
    name: "Definitions Edit",
    canGoBack: true,
    path: `${rootPath}/white-label/content-management/definitions-edit/:id`,
    component: ContentManagementDefinitionEdit,
  },
  {
    exact: true,
    name: "Templates List",
    canGoBack: false,
    path: `${rootPath}/white-label/content-management/templates-list`,
    component: ContentManagementTemplatesList,
  },
  {
    exact: true,
    name: "Template Edit",
    canGoBack: true,
    path: `${rootPath}/white-label/content-management/templates-edit/:id`,
    component: ContentManagementTemplatesEdit,
  },
  {
    exact: true,
    name: "Overrides List",
    canGoBack: false,
    path: `${rootPath}/white-label/content-management/overrides-list`,
    component: ContentManagementOverrides,
  },
  {
    exact: true,
    name: "Deployments List",
    canGoBack: false,
    path: `${rootPath}/white-label/content-management/deployments-list`,
    component: ContentManagementDeploymentsList,
  },
  {
    exact: true,
    name: "Deployments Edit",
    canGoBack: true,
    path: `${rootPath}/white-label/content-management/deployments-edit/:id`,
    component: ContentManagementDeploymentsEdit,
  },
  {
    exact: true,
    name: "Data Element Template Edit",
    canGoBack: true,
    path: `${rootPath}/data/data-element-template/:id`,
    component: DataElementTemplate,
  },
  {
    exact: true,
    name: "Data Element Template List",
    path: `${rootPath}/data/data-element-template-list`,
    component: DataElementTemplateList,
  },
  {
    exact: true,
    name: "Data Element Edit",
    canGoBack: true,
    path: `${rootPath}/data/data-element/:id`,
    component: DataElement,
  },
  {
    exact: true,
    name: "Data Element List",
    path: `${rootPath}/data/data-element-list`,
    component: DataElementList,
  },
  {
    exact: true,
    name: "Data Source Edit",
    canGoBack: true,
    path: `${rootPath}/data/data-source/:id`,
    component: DataSource,
  },
  {
    exact: true,
    name: "Data Sources List",
    path: `${rootPath}/data/data-source-list`,
    component: DataSourceList,
  },
  {
    exact: true,
    name: "Data Sources Reducers List",
    path: `${rootPath}/data/data-source-reducers-list`,
    component: DataSourcesReducersList,
  },
  {
    exact: true,
    name: "Data Source Reducer Edit",
    canGoBack: true,
    path: `${rootPath}/data/data-source-reducer/:id`,
    component: DataSourcesReducer,
  },
  {
    exact: true,
    name: "Data Source Partial Edit",
    canGoBack: true,
    path: `${rootPath}/data/data-source-partial/:id`,
    component: DataSourcePartial,
  },
  {
    exact: true,
    name: "Data Sources Partial List",
    path: `${rootPath}/data/data-source-partial-list`,
    component: DataSourcePartialList,
  },

  {
    exact: true,
    name: "Prize Templates List",
    canGoBack: false,
    path: `${rootPath}/templatization/prize-templates/list`,
    component: PrizeTemplatizationList,
  },
  {
    exact: true,
    name: "Prize Template Edit",
    canGoBack: true,
    path: `${rootPath}/templatization/prize-template/:id`,
    component: PrizeTemplatizationEdit,
  },
  {
    exact: true,
    name: "Prize Reward Instances",
    canGoBack: false,
    path: `${rootPath}/templatization/prize-reward-instances/list`,
    component: PrizeRewardInstances,
  },
  {
    exact: true,
    name: "Prize Reward Instance Edit",
    canGoBack: true,
    path: `${rootPath}/templatization/prize-reward-instance/:id`,
    component: PrizeRewardInstanceEdit,
  },

  // /prizes/prize-templates
  {
    exact: true,
    name: "Prize Templates List",
    path: `${rootPath}/prizes/prize-templates`,
    component: PrizeTemplatesList,
  },
  {
    exact: true,
    name: "Prize Template Edit",
    canGoBack: true,
    path: `${rootPath}/prizes/prize-templates/:id`,
    component: PrizeTemplateEdit,
  },
  {
    exact: true,
    name: "SMS Validation [Winner]",
    canGoBack: true,
    path: `${rootPath}/sms-codes/winner-sms-validation`,
    component: SmsValidationWinner,
  },
  {
    exact: true,
    name: "SMS Validation [WL]",
    canGoBack: true,
    path: `${rootPath}/sms-codes/wl-sms-validation`,
    component: SmsValidationWL,
  },
  {
    exact: true,
    name: "SMS Auth Codes",
    canGoBack: true,
    path: `${rootPath}/sms-codes/sms-auth-code`,
    component: SMSAuthCode,
  },

  {
    exact: true,
    name: "BridgerPay - Payment Methods",
    canGoBack: true,
    path: `${rootPath}/payments/payment-methods`,
    component: BridgerPaymentMethods,
  },

  {
    exact: true,
    name: "Affiliates Postback Tracking Settings",
    canGoBack: true,
    path: `${rootPath}/affiliates/postback-tracking/list`,
    component: PostbackTrackingSearch,
  },
  {
    exact: true,
    name: "New Affiliate Postback Tracking",
    canGoBack: true,
    path: `${rootPath}/affiliates/postback-tracking/affiliate/:tenantId`,
    component: PostbackTrackingEdit,
  },
  {
    exact: true,
    name: "Affiliate Postback Tracking Edit",
    canGoBack: true,
    path: `${rootPath}/affiliates/postback-tracking/affiliate/:tenantId/:id`,
    component: PostbackTrackingEdit,
  },
  {
    exact: true,
    name: "Traffic Networks",
    canGoBack: true,
    path: `${rootPath}/affiliates/traffic-networks/list`,
    component: TrafficNetworkSearch,
  },
  {
    exact: true,
    name: "New Traffic Network",
    canGoBack: true,
    path: `${rootPath}/affiliates/traffic-networks/network/:tenantId`,
    component: TrafficNetworkEdit,
  },
  {
    exact: true,
    name: "Traffic Network Edit",
    canGoBack: true,
    path: `${rootPath}/affiliates/traffic-networks/network/:tenantId/:id`,
    component: TrafficNetworkEdit,
  },

  {
    exact: true,
    name: "Calendar Campaigns",
    canGoBack: true,
    path: `${rootPath}/calendar/campaigns`,
    component: CalendarCampaignsList,
  },
  {
    exact: true,
    name: "Calendar Campaign Edit",
    canGoBack: true,
    path: `${rootPath}/calendar/campaigns/:id`,
    component: CalendarCampaignsEdit,
  },
  {
    exact: true,
    name: "Calendar Prizes",
    canGoBack: true,
    path: `${rootPath}/calendar/prizes`,
    component: CalendarPrizesList,
  },
  {
    exact: true,
    name: "Calendar Prize Edit",
    canGoBack: true,
    path: `${rootPath}/calendar/prizes/:id`,
    component: CalendarPrizesEdit,
  },
  {
    exact: true,
    name: "Players Campaign Data List",
    canGoBack: true,
    path: `${rootPath}/calendar/players-campaign-data`,
    component: CalendarPlayersCampaignDataList,
  },
  {
    exact: true,
    name: "Players Prize Data List",
    canGoBack: true,
    path: `${rootPath}/calendar/players-prize-data`,
    component: CalendarPlayersPrizeDataList,
  },
  {
    exact: true,
    name: "Players Operations List",
    canGoBack: true,
    path: `${rootPath}/calendar/players-operations`,
    component: CalendarPlayersOperationsList,
  },

  {
    exact: true,
    name: "Airdrop Campaigns",
    canGoBack: false,
    path: `${rootPath}/airdrop/campaigns`,
    component: AirdropCampaignList,
  },
  {
    exact: true,
    name: "Airdrop Campaign Edit",
    canGoBack: true,
    path: `${rootPath}/airdrop/campaigns/:id`,
    component: AirdropCampaignEdit,
  },
  {
    exact: true,
    name: "Airdrop Items",
    canGoBack: false,
    path: `${rootPath}/airdrop/items`,
    component: AirdropItemsList,
  },
  {
    exact: true,
    name: "Airdrop Items Edit",
    canGoBack: true,
    path: `${rootPath}/airdrop/items/:id`,
    component: AirdropItemsEdit,
  },
  {
    exact: true,
    name: "Airdrop Campaign Data List",
    canGoBack: false,
    path: `${rootPath}/airdrop/campaigns-item-data`,
    component: AirdropCampaignItemDataList,
  },
  {
    exact: true,
    name: "Airdrop Item Data List",
    canGoBack: false,
    path: `${rootPath}/airdrop/players-item-data`,
    component: AirdropPlayersItemDataList,
  },
  {
    exact: true,
    name: "Players Operations List",
    canGoBack: false,
    path: `${rootPath}/airdrop/players-operations`,
    component: AirdropPlayersOperationsList,
  },

  {
    exact: true,
    name: "Momentum Settings",
    // canGoBack: true,
    path: `${rootPath}/momentum/settings`,
    component: MomentumSettings,
  },

  {
    exact: true,
    name: "Momentum Binds",
    // canGoBack: true,
    path: `${rootPath}/momentum/binds`,
    component: MomentumBinds,
  },

  {
    exact: true,
    name: "Momentum Awards List",
    // canGoBack: true,
    path: `${rootPath}/momentum/awards-list`,
    component: MomentumAwardsList,
  },

  {
    exact: true,
    name: "Momentum Awards Details",
    canGoBack: true,
    path: `${rootPath}/momentum/awards/:uuid`,
    component: MomentumAwardsDetails,
  },

  {
    exact: true,
    name: "Customer Search Settings",
    path: `${rootPath}/customer-search-settings/:tenantId`,
    component: CustomerSearchSettings,
  },

  {
    exact: true,
    name: "Sync Profiles",
    canGoBack: true,
    path: `${rootPath}/sync/profiles`,
    component: SyncProfiles,
  },

  {
    exact: true,
    name: "Sync Profile Edit",
    canGoBack: true,
    path: `${rootPath}/sync/profile/:id`,
    component: SyncProfileEdit,
  },

  {
    exact: true,
    name: "Tools",
    canGoBack: true,
    path: `${rootPath}/sync/tools`,
    component: Tools,
  },

  {
    exact: true,
    name: "Consul",
    canGoBack: true,
    path: `${rootPath}/sync/consul`,
    component: ConsulEditor,
  },

  {
    exact: true,
    path: `${rootPath}/login`,
    component: Login,
    layout: "BlankLayout",
    meta: {
      authRoute: true,
    },
  },
  {
    exact: true,
    path: `${rootPath}/error`,
    component: lazy(() => import("../../views/Error")),
    layout: "BlankLayout",
  },
];

export { DefaultRoute, TemplateTitle, Routes };
