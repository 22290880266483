import { all, call, put, takeEvery, delay } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import store from "..";
import { bulkWithdrawals } from "../constants";
import { bulkWithdrawalsDone } from "../actions/bulk-withdrawals";

function* processSingleRequest(requestData, currentRequest) {
  const randomStartDelay = Math.random() * 500;  // between 0-500ms
  yield delay(randomStartDelay);
  const {url, withdrawId, postData} = requestData;

  let attempts = 0;
  let shouldRetry = true;

  const maxRetries = window.ini?.REACT_APP_BULK_TOOL_MAX_RETRIES ?? bulkWithdrawals.MAX_RETRIES;

  while (attempts < maxRetries && shouldRetry) {
    try {
      console.log(`Processing request ${currentRequest} - ${withdrawId} ... Attempt ${attempts + 1}`);
      // console.log({requestData});
      const {data: response} = yield call(axios.post, url, postData);
      return {
        success: true,
        requestData,
        indexInChunk: currentRequest,
        response,
      };
    } catch (e) {
      attempts++;
      console.error(`Processing request ${currentRequest} - ${withdrawId} ... Attempt ${attempts} failed: ${e.toString()}`);

      shouldRetry = e?.response?.status === 500;

      if (!shouldRetry || attempts >= maxRetries) {

        let message = e.toString();
        if (e?.response?.data?.error?.response) {
          const {ResponseCode, ResponseMessage} = e.response.data.error.response;
          if (ResponseCode || ResponseMessage) {
            message = `${ResponseMessage}${ResponseCode ? ` (ErrorCode: ${ResponseCode})` : ''}`;
          }
        }
        return {
          success: false,
          message,
          requestData,
          indexInChunk: currentRequest,
          response: e.response,
        };
      }

      // Calculate exponential backoff delay with jitter
      const baseDelay = 1000;  // Initial delay (1s)
      const backoffDelay = baseDelay * 2 ** (attempts - 1);  // Exponential backoff
      const jitter = Math.random() * 500;  // Random jitter (0-500 ms)

      yield delay(backoffDelay + jitter);
    }
  }
}

function* bulkWithdrawalsProgressSaga(action) {
  //processing current chunk
  const startTime = +new Date(); // Track global start time
  const { bulkWithdrawals: state } = store.getState();
  const { chunks, currentChunk } = state;

  console.warn("Saga: Progressing bulk bonus grant", {currentChunk});
  const maxConcurrentRequests = window.ini?.REACT_APP_BULK_TOOL_BATCH_SIZE ?? bulkWithdrawals.MAX_CONCURRENT_REQUESTS;

  if (currentChunk >= chunks.length) {
    yield put(bulkWithdrawalsDone({
      results: [],
      currentChunk: currentChunk,
      elapsedTime: 0,
    }));
    return;
  }

  const chunkStartTime = +new Date();
  const results = yield all(
    chunks[currentChunk].map((requestData, index) =>
      call(processSingleRequest, requestData, currentChunk * maxConcurrentRequests + index)
    )
  );
  const chunkEndTime = +new Date();
  const totalElapsedTime = chunkEndTime - startTime;
  console.log("Chunk processed. Chunk time:", chunkEndTime - chunkStartTime, "ms");
  console.log("Total elapsed time:", totalElapsedTime, "ms");

  yield put(bulkWithdrawalsDone({
    results: results.map((result, index) => ({
      ...result,
    })),
    currentChunk: currentChunk,
    elapsedTime: totalElapsedTime,
  }));
}

export default function* watchBulkDeductionGrantSaga() {
  yield takeEvery(bulkWithdrawals.PROGRESS, bulkWithdrawalsProgressSaga);
}
