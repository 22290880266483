import { bulkWithdrawals } from "../constants";

export const bulkWithdrawalsStart = data => ({
  type: bulkWithdrawals.START,
  data
});

export const bulkWithdrawalsDone = (data) => ({
  type: bulkWithdrawals.DONE,
  data
});

export const bulkWithdrawalsClear = () => ({
  type: bulkWithdrawals.CLEAR,
});

export const bulkWithdrawalsProgress = () => ({
  type: bulkWithdrawals.PROGRESS
});
